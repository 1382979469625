import React from "react";
import "./ourService.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { OurServiceBenifit, OurServiceCart } from "./home/HomeCurocel";
import FooterFirst from "./FooterFirst";
const additives = [
  "Performance enhancement: Our expert team creates custom compound formulations to enhance your plastics' performance, including strength, flame resistance, UV protection, and antistatic properties.",
  "Tailored solutions: We collaborate closely with you to address your specific needs and goals, ensuring customized formulations that meet your requirements.",
  "High-quality ingredients: We use top-quality additives and minerals to optimize the performance and durability of your plastics.",
  "Meticulous dispersion: Our service includes meticulous dispersion, ensuring uniform distribution of additives and minerals throughout the material.",
  "Consistent excellence: Expect reliable and repeatable results, thanks to our expertise and attention to detail.",
  "Industry leadership: Partnering with Plastic Innovation allows you to lead the industry by supercharging your plastics and gaining a competitive edge.",
];

 
const Compatibility = [
  {
    title: "Flame Resistance",
    img: `${require("./img/flameResistance.jpg")}`,
    text: "Slightly opaque white appearance used extensively in rigid bottle packaging, injection moulded caps and closures, crates etc.",
    bgcolor: "#73FAC9",
    tcolor: "black",
  },
  {
    title: "UV Protection",
    img: `${require("./img/uVProtection.jpg")}`,
    text: "Slightly clearer than HDPE and far more flexible, mostly used for flexible packaging, carrier bags, flexible tubes, film applications etc.",
    bgcolor: "#8FEC85",
    tcolor: "black",
  },
  {
    title: "Anti- Static properties",
    img: `${require("./img/antiStaticProperties.jpg")}`,
    text: "Polyesters are mechanically very strong and have good chemical resistance and barrier properties.",
    bgcolor: "#F9F871",
    tcolor: "black",
  },
  {
    title: "Strength improvement",
    img: `${require("./img/strengthImprovement.jpg")}`,
    text: "a thermoplastic crystalline engineering polymer that is often used as an insulator in electronics industries.",
    bgcolor: "#1772C5",
    tcolor: "white",
  },
  {
    title: "Fiber Glass addition",
    img: `${require("./img/fiber-glass.jpg")}`,
    text: "Slightly opaque white appearance used extensively in rigid bottle packaging, injection moulded caps and closures, crates etc.",
    bgcolor: "#73FAC9",
    tcolor: "black",
  },
  {
    title: "Metallic effect",
    img: `${require("./img/metallicEffect.jpg")}`,
    text: "Slightly clearer than HDPE and far more flexible, mostly used for flexible packaging, carrier bags, flexible tubes, film applications etc.",
    bgcolor: "#8FEC85",
    tcolor: "black",
  },
  {
    title: "Dispersion additive",
    img: `${require("./img/dispersionAdditive.jpg")}`,
    text: "Polyesters are mechanically very strong and have good chemical resistance and barrier properties.",
    bgcolor: "#F9F871",
    tcolor: "black",
  },
  {
    title: "Resin Blend",
    img: `${require("./img/resinBlend.jpg")}`,
    text: "a thermoplastic crystalline engineering polymer that is often used as an insulator in electronics industries.",
    bgcolor: "#1772C5",
    tcolor: "white",
  },
];

const compatibilitySection = () => {
  let arr = [];
  for (let i = 0; i < Compatibility?.length; i++) {
    arr.push(
      <div class="col-12 p-2" data-aos="zoom-in">
        <div
          class="campOption"
          style={{
            backgroundImage: `url(${Compatibility[i].img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div class="campOptionTitle d-grid justify-content-center ">
            <div>
              <p class="fs-3 fw-semibold  m-0">{Compatibility[i].title}</p>
            </div>
          </div>
          <div
            class="campOptionText"
            style={{ background: `${Compatibility[i].bgcolor}` }}
          >
            <p
              class="fs-4 fw-semibold mt-3 m-0"
              style={{ color: `${Compatibility[i].tcolor}` }}
            >
              {Compatibility[i].title}
            </p>
            <p class="text18" style={{ color: `${Compatibility[i].tcolor}` }}>
              {Compatibility[i].text}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return arr;
};

const ourValue = [
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Enhanced Performance: Boost your plastics' performance with our advanced compounding service.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Custom Formulations: Tailored compound formulations to meet your specific needs.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Strength and Durability: Improve strength, flame resistance, and UV protection of your plastics.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Top-Quality Ingredients: We use high-quality ingredients for consistent excellence.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Meticulous Dispersion: Our meticulous dispersion ensures optimal performance and consistency.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Industry Leadership: Collaborate with us to lead your industry with supercharged plastics.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Unleash Potential: Experience a powerful transformation for your plastic products.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Expert Team: Our skilled team delivers expertise and knowledge in compounding.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Innovation and Excellence: Trust Plastic Innovation for cutting-edge compounding solutions.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Contact us now: Take the first step to supercharging your plastics.",
  },
];

const OurValues = () => {
  const arr = [];
  for (let i = 0; i < ourValue.length; i++) {
    arr.push(
      <div class="col-12 text-start p-3" data-aos="fade-up">
        <img src={ourValue[i].icon} style={{ width: "60px" }} alt="" />
        {/* <p class='fw-bold fs-5 my-2'>Lorem ipsum dolor  enim ad minim</p> */}
        <p class="my-3">{ourValue[i].text}</p>
      </div>
    );
  }
  return arr;
};

const Additives = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <div id="additives">
      <div style={{ height: "80px" }}></div>
      <div class="text-start col-11 m-auto">
        <p class="display-1 fw-bold my-3" data-aos="fade-up">
          Additives & <br />
          Minerals Compounding
        </p>
      </div>
      <div
        class="row col-11 m-auto "
        style={{
          borderTop: "1px solid",
        }}
      >
        <div class="col-lg-4 col-12 " style={{ borderRight: "1px solid" }}>
          <div class="col-md-11 col-12 text-start my-4">
            <p class="fw-bold fs-3" data-aos="fade-up">
              Supercharge Your Plastics with Plastic Innovation's Additives &
              Minerals Compounding Service
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="col-md-12 col-12 text-start my-4 row">
            <div class="col-lg-1 col-12"></div>
            <div class="col-lg-11 col-12">
              <p
                class="text-secandry text22"
                data-aos="fade-up"
                style={{ textAlign: "justify" }}
              >
                Plastic Innovation offers an advanced Additives & Minerals
                Compounding Service to enhance your plastics' performance. Our
                expert team creates custom compound formulations tailored to
                your specific needs like boosting strength, flame resistance, UV
                protection, Antistatic and more. With top-quality ingredients
                and meticulous dispersion, our service ensures consistent
                excellence. Collaborate with us to supercharge your plastics and
                lead your industry. Contact us now for a powerful
                transformation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "70vh" }}>
        <img
          src={require("./img/ourServicePoster2.jpg")}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt=""
        />
      </div>
      <div></div>

      <div>
        <div class="col-11 m-auto my-4" data-aos="fade-up">
          <p class="display-5 m-auto text-start fw-normal">
            Plastic Innovation's Additives & Minerals Compounding Service offers
            the following advantages:
          </p>
        </div>
        <div class="col-11 m-auto">
          <ul class="text-start">
            {additives.map((el) => {
              return (
                <li style={{ listStyleType: "circle" }} data-aos="fade-up">
                  <p class="fs-5 text-secondary">{el}</p>
                </li>
              );
            })}
          </ul>
          <p class="text-start fs-4 fw-semibold " data-aos="fade-up">
            Transform your plastics with Plastic Innovation's Additives &
            Minerals Compounding Service. Contact us today for powerful
            performance enhancements
          </p>
        </div>
      </div>

      <div class="my-5" style={{ width: "95%", margin: "auto" }}>
        <p class="display-2 fw-semibold" data-aos="fade-up">
          Our Additive Range
        </p>
        <div class="mt-5">
          <OurServiceCart data={compatibilitySection()} />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <div class="col-12">
        <img
          src={require("../images/home/line2.png")}
          style={{ transform: "scaleX(-1)", width: "100%" }}
          alt=""
        />
      </div>
      <div style={{ position: "relative", top: "-160px" }}>
        <div class="ourservicBenefit text-white">
          <div class="ourservicBenefitHide" style={{ height: "150px" }}></div>
          <div class="col-12 m-auto text-start ">
            <div class="col-11 m-auto">
              <br />
              <p class="display-3 mb-5" data-aos="fade-up">
                {" "}
                <span>Our</span> <span class="fw-bold">Values</span>
              </p>
            </div>
            <br />
            <div class="row col-12  ">
              <div class="col-11 m-auto ">
                <OurServiceBenifit data={OurValues()} />
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>

      <div class="ourServisFooter">
        <br />
        <FooterFirst />
        <br />
      </div>
    </div>
  );
};

export default Additives;
