import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick"; 
import "./industrisSlide.css";
import { CgArrowLongRight, CgArrowLongLeft } from "react-icons/cg";
import AOS from "aos";
import "aos/dist/aos.css";

const data = [
  {
    img: `${require("../../images/home/industries/agriculture.jpg")}`,
    title: "Agriculture",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/automotive.jpg")}`,
    title: "Aerospace ",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/consumer-electronics.jpg")}`,
    title: "Consumer Electronics",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/daily-care-products.webp")}`,
    title: "Daily Care Products",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/entertainment.jpg")}`,
    title: "Entertainment",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/fire.png")}`,
    title: "Government ",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/toy.jpg")}`,
    title: "Toys CPSC compliant",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/military.jpg")}`,
    title: "Military",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/packaging.jpg")}`,
    title: "Packaging",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    img: `${require("../../images/home/industries/medical.jpg")}`,
    title: "Medical ",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
];

const IndustriesSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const refIndustries = useRef(null);

  useEffect(() => {
    const industriesBg = document.querySelector(".industriesBg");

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            industriesBg.classList.add("active-industriesBg");
          }, 100);
        } else {
          // industriesBg.classList.remove('active-industriesBg')
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refIndustries.current) {
      observer.observe(refIndustries.current);
    }
    return () => {
      if (refIndustries.current) {
        observer.unobserve(refIndustries.current);
      }
    };
  }, [refIndustries]);

  function SampleNextArrow({ onClick }) {
    return (
      <div class="arrow arrow-right" onClick={onClick}>
        <CgArrowLongRight class="fs-1" />
      </div>
    );
  }

  function SamplePrevArrow({ onClick }) {
    return (
      <div class="arrow arrow-left" onClick={onClick}>
        <CgArrowLongLeft class="fs-1" />
      </div>
    );
  }

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay:true,
    // autoplaySpeed:1000,
    beforeChange: (current, next) => setSlideIndex(next),
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({ duration: 3000 });
  });

  return (
    <div class="industrieMain mb-5" ref={refIndustries}>
      <div class="industriesBg"></div>
      <div class="slider mb-5">
        <div class="industriHide"></div>
        <p class="display-3 mb-5  ">
          <span class="fw-semibold">Industries We</span> <br />
          <span class="fw-bold">serve</span>
        </p>
        <Slider {...settings}>
          {data.map((el, i) => {
            return (
              <div>
                <div
                  class={i == slideIndex ? "slide slide-active" : "slide"}
                  key={i}
                >
                  <img src={el.img} alt="" />
                </div>
                <div
                  class={i == slideIndex ? "slide slide-active" : "slide"}
                  key={i}
                >
                  <div class="industriesText my-4">
                    <p class="fw-bold fs-5 ">{el.title}</p>
                    {/* <p style={{maxWidth:'500px',margin:'auto'}}>{el.text}</p>  */}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div class="industriHide"></div>
    </div>
  );
};

export default IndustriesSlider;
