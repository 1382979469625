import React, { useEffect, useRef } from "react";
import "./scrolled.css";
import DownArrow from "./DownArrow";

const Scrolled = ({data}) => {
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);

  useEffect(() => {
    const box1 = box1Ref.current;
    const box2 = box2Ref.current;
    const line = document.querySelector('.drowline')
    const modifier = window.innerWidth / 520;

    const handleScroll = () => {
      const scrollY = window.scrollY ; 
      box1.style.clipPath = `inset(0 2% ${( scrollY * modifier)/2}px 2% round 0px 0px 50px 50px)`; 
      line.style.clipPath = `inset(0  ${(100-scrollY/5)}% 0  0)` 
       
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ height: "200vh" }}>
      <section>
        <div className="box box1  " ref={box1Ref}>
        <div style={{height:'80px'}}></div> 
          <div class="row col-11 m-auto align-items-center "> 
            <div class="col-md-6 col-12 text-start">
              <p class="display-1 fw-bold  topText">PLASTIC INNOVATION</p>
              <br />
              <p class=" fs-5 topText2 " style={{maxWidth:'500px'}}>
                We are designed to improve the appearance, performance, value &
                sustainability of plastic moldings.
              </p>
              <button class="btn btn-primary rounded-5 fw-bold py-3 px-4 clearBtn my-5"  onClick={()=>data(true)}>
                Request Free Color Match
              </button>
              <DownArrow/>
            </div>
            <div class="col-md-6 col-12 m-auto text-start hometopMobile">
            <div class="col-md-10 col-12 m-auto" style={{position:'relative'}}>
                <img 
                  style={{ width: "90%"}}
                  src={require("../../images/home/chipset.png")}
                  alt=""
                />
               
              </div>
            </div>
          </div>
        </div>
        <div style={{height:'80px'}}></div> 
        <div class='col-12 drowlineDive'>
            <img class='drowline'  src={require('../../images/home/line.png')} alt="" />
        </div>
        <div class='box-2'>  
          <div class="row col-11 m-auto  align-items-center">
            <div class="col-md-6 col-12 text-start">
              <p class="display-1 fw-bold topText text-black">PLASTIC INNOVATION</p>
              <br />
              <p class="fs-5 " style={{maxWidth:'500px'}}>
                We are designed to improve the appearance, performance, value &
                sustainability of plastic moldings.
              </p>
              <button class="btn btn-primary rounded-5 fw-bold py-3 px-4 clearBtn my-5"  onClick={()=>data(true)}>
                Request Free Color Match
              </button>
              <DownArrow/>
            </div>
            <div class="col-md-6 col-12 m-auto text-start hometopMobile2">
              <div class="col-md-10 col-12 m-auto" style={{position:'relative'}}>
                <img 
                  style={{ width: "90%"}}
                  src={require("../../images/home/chipset.png")}
                  alt=""
                />
               
              </div>
            </div>
          </div> 
         
        </div>
      </section>
    </div>
  );
};

export default Scrolled;
