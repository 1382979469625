import React from "react";
import "./ourService.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { OurServiceBenifit, OurServiceCart } from "./home/HomeCurocel";
import FooterFirst from "./FooterFirst";

const preColor = [
  "Customized brilliance: Vibrant and consistent colors from the core, reflecting your brand identity.",
  "Elimination of secondary coloring: Saves time and reduces costs by eliminating additional coloring processes.",
  "Enhanced performance and durability: Additives improve strength and resistance of plastic products.",
  "Personalized guidance: Expert team provides tailored support throughout the process.",
  "Unwavering commitment: Dedicated to customer satisfaction and delivering high-quality results.",
  "Elevates brand image: Memorable and impactful, leaving a lasting impression on customers.",
];
 

 

 

const ourValue = [
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Customized Brilliance: Elevate your plastic products with vibrant and consistent colors.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Advanced Technology: Experience groundbreaking Pre-Color Resin Service for exceptional performance.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Eliminate Secondary Coloring: Our service eliminates the need for additional coloring processes.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Enhanced Strength and Resistance: Additives boost product durability and strength.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Personalized Guidance: Our team offers tailored support to bring your vision to life.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Unwavering Commitment: We are dedicated to delivering exceptional results.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Elevate Your Brand: Stand out in the market with our Pre-Color Resin Service.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Leave an Indelible Mark: Make a lasting impression with brilliantly colored products.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Unleash Brilliance Today: Contact us to unlock the full potential of your products.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Experience the Difference: Trust Plastic Innovation for unparalleled color solutions.",
  },
];

const OurValues = () => {
  const arr = [];
  for (let i = 0; i < ourValue.length; i++) {
    arr.push(
      <div class="col-12 text-start p-3" data-aos="fade-up">
        <img src={ourValue[i].icon} style={{ width: "60px" }} alt="" />
        {/* <p class='fw-bold fs-5 my-2'>Lorem ipsum dolor  enim ad minim</p> */}
        <p class="my-3">{ourValue[i].text}</p>
      </div>
    );
  }
  return arr;
};

const PreColor = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <div id="preColor">
      <div style={{ height: "80px" }}></div>
      <div class="text-start col-11 m-auto">
        <p class="display-1 fw-bold my-3" data-aos="fade-up">
          Pre Colored <br />
          Resin
        </p>
      </div>
      <div
        class="row col-11 m-auto "
        style={{
          borderTop: "1px solid",
        }}
      >
        <div class="col-lg-4 col-12 " style={{ borderRight: "1px solid" }}>
          <div class="col-md-11 col-12 text-start my-4">
            <p class="fw-bold fs-3" data-aos="fade-up">
              Illuminate Your Products with Plastic Innovation's Pre-Color Resin
              Service
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="col-md-12 col-12 text-start my-4 row">
            <div class="col-lg-1 col-12"></div>
            <div class="col-lg-11 col-12">
              <p
                class="text-secandry text22"
                data-aos="fade-up"
                style={{ textAlign: "justify" }}
              >
                Plastic Innovation presents our ground breaking Pre-Color Resin
                Service, empowering your plastic products with customized
                brilliance. Our advanced technology and expert team ensure
                vibrant and consistent colors from the core, eliminating the
                need for secondary coloring processes. Experience exceptional
                performance and durability as our additives enhance your
                products' strength and resistance. With personalized guidance
                and unwavering commitment, Plastic Innovation brings your vision
                to life. Elevate your brand with our Pre-Color Resin Service and
                leave an indelible mark. Contact us to unleash brilliance today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "70vh" }}>
        <img
          src={require("../images/home/pre-color-resin.jpg")}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt=""
        />
      </div>

      <div>
        <div class="col-11 m-auto my-4" data-aos="fade-up">
          <p class="display-5 m-auto text-start fw-normal">
            Benefits of Pre Color Resin{" "}
          </p>
        </div>
        <div class="col-11 m-auto">
          <ul class="text-start">
            {preColor.map((el) => {
              return (
                <li style={{ listStyleType: "circle" }} data-aos="fade-up">
                  <p class="fs-5 text-secondary">{el}</p>
                </li>
              );
            })}
          </ul>

          <p class="text-start fs-4 fw-semibold " data-aos="fade-up">
            Elevate your plastic products with Plastic Innovation's Pre Color service. Contact us to unlock the power of captivating
            colors today.
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <div class="col-12">
        <img
          src={require("../images/home/line2.png")}
          style={{ transform: "scaleX(-1)", width: "100%" }}
          alt=""
        />
      </div>
      <div style={{ position: "relative", top: "-100px" }}>
        <div class="ourservicBenefit text-white">
          <div class="ourservicBenefitHide" style={{ height: "150px" }}></div>
          <div class="col-12 m-auto text-start ">
            <div class="col-11 m-auto">
              <br />
              <p class="display-3 mb-5" data-aos="fade-up">
                {" "}
                <span>Our</span> <span class="fw-bold">Values</span>
              </p>
            </div>
            <br />
            <div class="row col-12  ">
              <div class="col-11 m-auto ">
                <OurServiceBenifit data={OurValues()} />
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>

      <div class="ourServisFooter" >
        <br />
        <br />
        <br />
        <FooterFirst />
        <br />
      </div>
    </div>
  );
};

export default PreColor;
