import React, { useEffect, useState } from "react";

const ScrolledImg = ({data}) => {
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const rotateDeg = scrollPos % 360;

  const imageStyle = {
    transform: `rotate(${rotateDeg}deg)`,
    width: "80%", 
    cursor:'pointer'
  };
  return (
    <img
      class="App-logo"
      
      style={imageStyle}
      src={require("../images/home/home13.png")}
      alt=""
      onClick={()=>data(true)}
    />
  );
};

export default ScrolledImg;
