import React from "react";
import { useNavigate } from "react-router-dom";
import Typed from "react-typed";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const FooterFirst = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  return (
    <div>
      <div
        class="rounded-5 "
        style={{ width: "98%", margin: "auto", background: "#8FEC85" }}
        data-aos="zoom-in">
        <div class="row">
          <div class="col-lg-7 col-12 row">
            <div class="col-11 row  m-auto ">
              <div class="col-9 p-3 text-start my-5">
                <p class=" display-4" style={{ maxWidth: " 550px" }}>
                  <span class="" style={{ fontWeight: "regular " }}>
                    Enquire today for your
                  </span>
                  <span class="fw-bold" style={{ marginLeft: "10px" }}>
                    <Typed
                      strings={[" Business."]}
                      typeSpeed={80}
                      backSpeed={80}
                      loop
                    />
                  </span>
                </p>
              </div>
              <div class="col-3 d-flex align-items-center">
                <img
                  style={{ marginLeft: "-50px" }}
                  src={require("../images/home/linedrow1.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12 d-flex align-items-center">
            <div class="col-10 m-auto my-4">
              <button
                class="btn rounded-5 py-4"
                style={{ width: "100%", background: "#AEFFA6" }}
              >
                info@Plasticinnovations.com
              </button>
              <button
                class="btn rounded-5 py-4 mt-4 text-white"
                style={{ width: "100%", background: "#1772C5" }}
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterFirst;
