import React from 'react'
import './downArrow.css'

const DownArrow = () => {
  return (
    <div class="arrow-down"> 
        <div class="left"></div>
        <div class="right"></div> 
    </div>
  )
}

export default DownArrow