import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { OurServiceBenifit, OurServiceCart } from "../home/HomeCurocel";
import "./product.css";
import FooterFirst from "../FooterFirst";
import ScrolledImg from "../ScrolledImg";
import { useState } from "react";
import {
  BsArrowRightCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom"; 
 

const Products = ({data}) => {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);
  const [faq6, setFaq6] = useState(false);
  const [faq7, setFaq7] = useState(false);
  const [faq8, setFaq8] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  const navigate = useNavigate()
  return (
    <div>
      <div style={{ height: "80px" }}></div>
      <div class="text-start col-11 m-auto">
        <p class="display-1 fw-bold my-3" data-aos="fade-up">
          Product <br />
          Development
        </p>
      </div>
      <div class="row col-11 m-auto " style={{ borderTop: "1px solid" }}>
        <div class="col-lg-4 col-12 " style={{ borderRight: "1px solid" }}>
          <div class="col-md-11 col-12 text-start my-4">
            <p class="fw-bold fs-3" data-aos="fade-up">
              PII is proud to offer innovative ideas and solutions to our
              customer base.{" "}
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="col-md-10 col-12 text-start my-4 row">
            <div class="col-lg-1 col-12"></div>
            <div class="col-lg-11 col-12">
              <p
                class="text-secandry text22"
                data-aos="fade-up"
                style={{ textAlign: "justify" }}
              >
                We strive to be leaders in the field of masterbatch by offering
                not only pigmented and special effect solutions but also by
                exploring new ideas, technologies, and additives to enhance our
                product portfolio.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5" style={{ position: "relative", height: "80vh" }}>
        <img
          src={require("./images/pantone.jpg")}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt=""
        />

        <div
          style={{
            position: "absolute",
            top: "-100px",
            width: "250px",
            right: "10%",
            zIndex: "1",
          }}
        >
          <ScrolledImg data={data} />
        </div>
      </div>

      <div class="col-11 m-auto">
        <div class=" row   mt-5">
          <div class="col-md-4 col-12 text-start ">
            <p class="display-5">
              <span class="fw-normal">Transforming </span> <br />
              <span class="fw-bold">
                Ideas into <br /> Reality
              </span>
            </p>
            {/* <p class="fw-semibold">
              ad minima veniam, quis nostrum exercitationem ullam corporis
              suscipit laboriosam, nisi ut alidex ea commodi consequatur? Quis
              autem vel eum
            </p> */}
          </div>
          <div class="col-md-8 col-12 text-start">
            <p class="fs-5" style={{ textAlign: "justify" }}>
              Our rigorous product development process ensures the highest level
              of precision and quality. It begins with color matching using
              Pantone codes or sample parts, followed by the creation of a
              unique formula in our state-of-the-art lab using scientifically
              engineered pigments and additives.
            </p>
            <br />
            <p class="fs-5" style={{ textAlign: "justify" }}>
              To ensure customer satisfaction, we provide color chips for
              approval, showcasing the vibrant range of colors available through
              Plastic Innovation. This visual representation helps in the
              decision-making process between color concentrate or pre-color
              options.
            </p>
            <br />
            <p class="fs-5" style={{ textAlign: "justify" }}>
              Once the decision is made, our streamlined production processes go
              into action, ensuring efficient manufacturing while maintaining
              strict quality control. We take pride in delivering your products
              promptly, with reliable logistics and a commitment to meeting your
              deadlines.
            </p>
            <br />
            <p class="fs-5" style={{ textAlign: "justify" }}>
              Experience our professional product development process and let us
              bring your ideas to life. Contact us today to embark on a
              transformative journey with Plastic Innovation.
            </p>
         
          </div>
        </div>
      </div>

    
      <br /><br />
      <div style={{ background: "#1F2224" }}>
        <p class="display-3 fw-bold text-white py-5">FAQs</p> 
        
        <div class="col-11 m-auto">
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq1
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq1(!faq1)}
              data-bs-toggle="collapse"
              href="#faq1"
              role="button"
              aria-expanded="false"
              aria-controls="faq1"
            >
              <div>
                <p class="fs-5 ">
                  How does Plastic Innovations Inc. ensure accurate color
                  matching?
                </p>
              </div>
              <div>
                {faq1 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq1">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  We utilize Pantone codes or sample parts to achieve precise
                  color matching, ensuring consistency and accuracy in our color
                  formulations.
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq2
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq2(!faq2)}
              data-bs-toggle="collapse"
              href="#faq2"
              role="button"
              aria-expanded="false"
              aria-controls="faq2"
            >
              <div>
                <p class="fs-5 ">
                  How long does the color matching process take?
                </p>
              </div>
              <div>
                {faq2 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq2">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our typical color matching process can be completed within 2-3
                  business days. If you require a quicker turnaround, we offer
                  an expedited 24-hour color match service at no additional
                  charge.
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq3
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq3(!faq3)}
              data-bs-toggle="collapse"
              href="#faq3"
              role="button"
              aria-expanded="false"
              aria-controls="faq3"
            >
              <div>
                <p class="fs-5 ">
                  What is the formulation process for creating unique formulas?
                </p>
              </div>
              <div>
                {faq3 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq3">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our state-of-the-art lab employs a scientific approach to
                  formulate unique formulas by carefully combining pigments and
                  additives, resulting in vibrant and customized colors.
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq4
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq4(!faq4)}
              data-bs-toggle="collapse"
              href="#faq4"
              role="button"
              aria-expanded="false"
              aria-controls="faq4"
            >
              <div>
                <p class="fs-5 ">
                  Can I see color samples before making a decision?
                </p>
              </div>
              <div>
                {faq4 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq4">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Absolutely! We provide color chips for customer approval,
                  showcasing a wide range of colors available through Plastic
                  Innovations Inc. These color chips help you visualize the
                  final result and make informed decisions.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq5
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq5(!faq5)}
              data-bs-toggle="collapse"
              href="#faq5"
              role="button"
              aria-expanded="false"
              aria-controls="faq5"
            >
              <div>
                <p class="fs-5 ">
                  How does the decision-making process work between color
                  concentrate and pre-color options?
                </p>
              </div>
              <div>
                {faq5 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq5">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our experts will guide you through the decision-making process
                  based on your specific requirements. We take into account
                  factors such as production volume, desired color accuracy, and
                  application to determine the most suitable option for your
                  project.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq6
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq6(!faq6)}
              data-bs-toggle="collapse"
              href="#faq6"
              role="button"
              aria-expanded="false"
              aria-controls="faq6"
            >
              <div>
                <p class="fs-5 ">
                  What is the timeline for production and delivery?
                </p>
              </div>
              <div>
                {faq6 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq6">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our streamlined production processes enable us to maintain
                  shorter lead times. Our normal lead time is less than 10
                  business days. The exact timeline will depend on the project
                  scope and specifications. Rest assured, we prioritize prompt
                  delivery to meet your deadlines.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq7
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq7(!faq7)}
              data-bs-toggle="collapse"
              href="#faq7"
              role="button"
              aria-expanded="false"
              aria-controls="faq7"
            >
              <div>
                <p class="fs-5 ">
                  Does Plastic Innovations Inc. offer customization and
                  personalized consultations?
                </p>
              </div>
              <div>
                {faq7 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq7">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Absolutely! We pride ourselves on providing customized
                  solutions tailored to your unique needs. Our team of experts
                  offers personalized consultations to understand your
                  requirements and ensure that we deliver the best possible
                  results.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq8
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq8(!faq8)}
              data-bs-toggle="collapse"
              href="#faq8"
              role="button"
              aria-expanded="false"
              aria-controls="faq8"
            >
              <div>
                <p class="fs-5 ">
                  What quality assurance measures does Plastic Innovations Inc.
                  have in place?
                </p>
              </div>
              <div>
                {faq8 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq8">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Quality is our utmost priority. We have rigorous quality
                  control measures in place, and our products meet or exceed
                  industry standards. Additionally, we hold relevant
                  certifications that validate our commitment to maintaining the
                  highest quality standards.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary rounded-5 fw-bold p-4 my-5" onClick={()=>navigate('/contact')}>
        Have more questions, Contact us
        </button>
      </div>

      <div class="applicationFooter">
        <br />
        <FooterFirst />

        <br />
      </div>
    </div>
  );
};

export default Products;
