import React from "react";
import { useNavigate } from "react-router-dom";

export const Footer = ({data}) => {
  const navigate = useNavigate();
  return (
    <div style={{ background: "white", position: "relative" }}>
      <br />
      <br />
      <div class="col-11 row m-auto  ">
        <div class="col-lg-8 col-12 row gap-4">
          <div class="col-md-6 col-12 p-2">
            <div style={{ width: "195px" }}>
              <img
                style={{ width: "100%" }}
                src={require("../images/home/logo.png")}
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 col-12 row text-start p-2 ">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/about-us")}
            >
              About Us
            </div>
            <a
              style={{ textDecoration:'none' ,color:'black',cursor:'pointer' }}
              // href= "/color-consentrate" 
              onClick={() => navigate("/color-consentrate")}
            >
              Color Concentrate Service
            </a>
            <a
              style={{textDecoration:'none' ,color:'black',cursor:'pointer'}}
              // href= "/pre-colored-resin" 
              onClick={() => navigate("/pre-colored-resin")}
            >
              Pre–Color Service
            </a>
            <a
              style={{textDecoration:'none' ,color:'black',cursor:'pointer'}}
              // href= "/additives-minerals"
              onClick={() => navigate("/additives-minerals")}
            >
              Additives Service
            </a>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/product")}
            >
              Product Development
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12  text-start p-0">
          <p class=" fw-bold">Contact Us</p>
          <p class="">
            {" "}
            <span class="fw-semibold"> Phone :</span> +1 951 361 0251
          </p>
          <p class="">
            {" "}
            <span class="fw-semibold"> Email :</span>{" "}
            info@Plasticinnovations.com{" "}
          </p>
          <p class="">
            {" "}
            <span class="fw-semibold"> Address :</span> 10513 San Sevaine
            WayJurupa Valley, CA USA 91752{" "}
          </p>
        </div>
      </div>
      <br />
      <div
        class="rounded-5 row bg-black p-3 gap-3"
        style={{ width: "98%", margin: "auto", fontSize: "16px" }}
      >
        <div class="col-sm-7 col-12 text-white   text-start">
          © 2023 PII | Designed by{" "}
          <a href="https://hi-labsolution.com/" target="_blank">
            <img
              src="https://hi-labsolution.com/hlogo.png"
              alt="Hi-Lab Solution"
            />
          </a>
        </div>
        <div class="col-sm-4 col-12 row ">
          {/* <div class="col-md-6 col-12 text-white  text-start">
            Terms & Conditions
          </div> */}
          {/* <div class="col-md-6 col-12 text-white  text-start"> */}
          {/* Privacy Policy */}
          
          <p class='text-white text-sm-end text-start' onClick={()=>data(true)} style={{cursor:'pointer'}}> 
            Privacy Policy
          </p>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
