import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const WhyPlasticInnovation = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 790 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 790, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={false}
      infinite={true}
      arrows={true}
    >
      {data?.map((el) => {
        return <>{el}</>;
      })}
    </Carousel>
  );
};

export const Testimonials = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 790 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 790, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return (
          <>
            {el}
            <br />
          </>
        );
      })}
    </Carousel>
  );
};

export const OurPartners = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return (
          <div class="d-flex align-items-center justify-content-center">
            {el}
            <br />
          </div>
        );
      })}
    </Carousel>
  );
};

export const Compatibility1 = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return (
          <>
            {el}
            <br />
          </>
        );
      })}
    </Carousel>
  );
};

export const OfferMini = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return (
          <>
            {el}
            <br />
          </>
        );
      })}
    </Carousel>
  );
};

export const AboutOurValue = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return <>{el}</>;
      })}
    </Carousel>
  );
};

export const OurServiceCart = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 900, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return (
          <>
            {el}
            <br />
          </>
        );
      })}
    </Carousel>
  );
};

export const OurServiceBenifit = ({ data }) => {
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 900, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsiveGameSlider}
      autoPlay={true}
      showDots={false}
      infinite={true}
      arrows={false}
    >
      {data?.map((el) => {
        return (
          <>
            {el}
            <br />
          </>
        );
      })}
    </Carousel>
  );
};
