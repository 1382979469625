import React, { useEffect, useRef } from "react";
import "../Style.css";
import "./locomotive.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Compatibility1,
  OurPartners,
  Testimonials,
  WhyPlasticInnovation,
} from "./HomeCurocel";
import IndustriesSlider from "./IndustriesSlider";

import { CgArrowLongRight } from "react-icons/cg";
import { useState } from "react";
import Scrolled from "./Scrolled";
import LeftSlide from "./LeftSlide";

import { Link } from "react-scroll";
import Offer from "./Offer";
import ScrolledImg from "../ScrolledImg";
import { useNavigate } from "react-router-dom";
import FooterFirst from "../FooterFirst";
import "./plasticInovation.css";

const whyPlasticIn = [
  {
    img: `${require("../../images/home/certificate.png")}`,
    title: "ISO 9001:2015 CERTIFICATE",
    text: "Quality Management System Certificate",
  },
  {
    img: `${require("../../images/home/home18.png")}`,
    title: "Expertise",
    text: "Unmatched knowledge and experience in plastic innovation",
  },
  {
    img: `${require("../../images/home/home17.png")}`,
    title: "Technology",
    text: "Cutting-edge tools and equipment for advanced solutions",
  },
  {
    img: `${require("../../images/home/industries/packaging.jpg")}`,
    title: "Customization",
    text: "Tailored solutions to meet unique requirements and goals.",
  },
  {
    img: `${require("../../images/home/home16.png")}`,
    title: "Quality Control",
    text: "Stringent measures ensure exceptional product reliability and durability.",
  },
  {
    img: `${require("../../images/home/industries/daily-care-products.webp")}`,
    title: "Customer Satisfaction",
    text: "Committed to exceeding expectations through communication and collaboration.",
  },
];

const whyPlasticInnovations = () => {
  let arr = [];
  for (let i = 0; i < whyPlasticIn.length; i++) {
    arr.push(
      <div>
        <div>
          <img
            src={whyPlasticIn[i].img}
            style={{
              width: "238px",
              height: "296px",
              borderRadius: "50px",
              objectFit: "cover",
            }}
            alt=""
          />
        </div>
        <div class="p-3">
          <p class="fs-5 fw-semibold mx-3">{whyPlasticIn[i].title}</p>
          <p class=" ">{whyPlasticIn[i].text}</p>
        </div>
      </div>
    );
  }
  return arr;
};

const companys = [
  {
    icon: `${require("../../images/home/icon1.png")}`,
    text: "Plastic Innovation's color solutions transformed our products, making them visually stunning and durable. They are our go-to choice.",
    location: "Nick, Poly-Extrusions, Inc",
  },
  {
    icon: `${require("../../images/home/icon2.png")}`,
    text: "The innovative approach of Plastic Innovation's Research Lab gave our products a competitive edge. Exceptional customer service!",
    location: "George, Performance Engineered Products, California ",
  },
  {
    icon: `${require("../../images/home/icon1.png")}`,
    text: "Plastic Innovation's customized additives elevated our plastic products' performance. Their professionalism and understanding of our requirements were exceptional.",
    location: " Mark, Plastic Moulder",
  },
];

const testimonial = () => {
  let arr = [];
  for (let i = 0; i < companys.length; i++) {
    arr.push(
      <div class="col-11 m-auto">
        <div>
          <img style={{ width: "70px" }} src={companys[i].icon} alt="" />
        </div>
        <br />
        <div>
          <p class=" text-white">{companys[i].text}</p>
          <br />
          <p class="fs-5 text-white fw-bold">{companys[i].location}</p>
        </div>
      </div>
    );
  }
  return arr;
};

const partners = [
  {
    img: `${require("../../images/home/partners/c1.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c2.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c3.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c4.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c5.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c6.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c7.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c8.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c9.png")}`,
  },
  {
    img: `${require("../../images/home/partners/c10.webp")}`,
  },
];

const partner = () => {
  let arr = [];
  for (let i = 0; i < partners.length; i++) {
    arr.push(
      <div class="d-flex align-items-center justify-content-center">
        <img style={{ width: "160px" }} src={partners[i].img} alt="" />
      </div>
    );
  }
  return arr;
};

const Compatibility = [
  {
    title: "Polypropylene / PolyEthelene",
    img: `${require("../../images/home/home11.png")}`,
    text: "Transform your products with vibrant color solutions for polypropylene and polyethylene materials.",
    bgcolor: "#73FAC9",
    tcolor: "black",
  },
  {
    title: "Ultem",
    img: `${require("../../images/home/material/ultem.jpg")}`,
    text: "Discover the exceptional qualities of Ultem material. Unmatched strength, heat resistance, and chemical resistance for high-performance applications.",
    bgcolor: "#8FEC85",
    tcolor: "black",
  },
  {
    title: "PolyCarbonate",
    img: `${require("../../images/home/material/POlucarbonate.jpg")}`,
    text: "Unlock the potential of PolyCarbonate material. Exceptional strength,  transparency, and impact resistance for diverse industrial applications.",
    bgcolor: "#F9F871",
    tcolor: "black",
  },
  {
    title: "Polysulphone (PSU)",
    img: `${require("../../images/home/material/polysulphone-psu.jpg")}`,
    text: "Experience the superiority of Polysulphone (PSU) material. Unrivaled heat resistance, chemical compatibility, and dimensional stability for demanding industrial  applications.  ",
    bgcolor: "#1772C5",
    tcolor: "white",
  },
  {
    title: "Polyphenylene sulfide (PPS)",
    img: `${require("../../images/home/material/pps.jpg")}`,
    text: "Elevate your plastic color  services with Polyphenylene Sulfide (PPS) material. Exceptional heat  resistance and chemical compatibility for vibrant and durable color solutions.",
    bgcolor: "#73FAC9",
    tcolor: "black",
  },
  {
    title: "TPU / TPE / TPV ",
    img: `${require("../../images/home/material/TPU-TPE.jpg")}`,
    text: "  Enhance  your plastic color services with TPU, TPE, and TPV materials. Versatile, flexible, and durable solutions for vibrant and customized color applications.",
    bgcolor: "#8FEC85",
    tcolor: "black",
  },
  {
    title: "Nylon6, 6/6, 6/12",
    img: `${require("../../images/home/material/nylon.jpg")}`,
    text: "Exceptional strength, durability, and versatility for vibrant color solutions.",
    bgcolor: "#F9F871",
    tcolor: "black",
  },
  {
    title: "Peek ",
    img: `${require("../../images/home/material/peek.jpg")}`,
    text: "Unmatched high-performance properties, heat  resistance, and chemical compatibility for vibrant plastic color solutions. ",
    bgcolor: "#1772C5",
    tcolor: "white",
  },
  {
    title: "ABS – Unfilled, Glass and FR",
    img: `${require("../../images/home/material/abs.jpg")}`,
    text: " Unfilled, Glass and FR: unfilled, glass-filled, and flame-retardant. Durable, customizable, and vibrant plastic color solutions for various applications.",
    bgcolor: "#73FAC9",
    tcolor: "black",
  },
  {
    title: "SAN",
    img: `${require("../../images/home/material/SAN.jpg")}`,
    text: "Exceptional clarity, impact resistance, and color vibrancy for stunning plastic color solutions.",
    bgcolor: "#8FEC85",
    tcolor: "black",
  },
  {
    title: "GPPS",
    img: `${require("../../images/home/material/gpps.jpg")}`,
    text: "High clarity, excellent process ability, and vibrant color options for exceptional results.",
    bgcolor: "#F9F871",
    tcolor: "black",
  },
  {
    title: "EVA",
    img: `${require("../../images/home/material/eva.jpg")}`,
    text: "Versatile, flexible, and excellent color compatibility for vibrant and dynamic applications.",
    bgcolor: "#1772C5",
    tcolor: "white",
  },
];

function createArrayOfObjects(arr, subLength) {
  const result = [];
  for (let i = 0; i < arr.length; i += subLength) {
    result.push(arr.slice(i, i + subLength).map((val) => val));
  }
  return result;
}

const subLength = 4;
const compatiSecion = createArrayOfObjects(Compatibility, subLength);

const compatibilitySection = () => {
  let arr = [];
  for (let i = 0; i < compatiSecion?.length; i++) {
    arr.push(
      <div class="row">
        {compatiSecion[i].map((el) => {
          return (
            <div class="col-sm-6 col-12 p-2" data-aos="zoom-in">
              <div
                class="campOption"
                style={{
                  backgroundImage: `url(${el.img})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                }}
              >
                <div class="campOptionTitle d-grid justify-content-center ">
                  <div>
                    <p class="fs-2 fw-semibold text-wite m-0">{el.title}</p>
                  </div>
                </div>
                <div
                  class="campOptionText"
                  style={{ background: `${el.bgcolor}` }}
                >
                  <p
                    class="fs-3 fw-semibold mt-3 m-0"
                    style={{ color: `${el.tcolor}` }}
                  >
                    {el.title}
                  </p>
                  <p
                    class=" "
                    style={{ color: `${el.tcolor}`, fontSize: "16px" }}
                  >
                    {el.text}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return arr;
};

const Home = ({ data }) => {
  const [divSection, setDivSection] = useState("");
  const [scroll, setScroll] = useState(400);

  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  const refWhyPin = useRef(null);

  useEffect(() => {
    const whyPinBg = document.querySelector(".whyPinC");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          whyPinBg.classList.add("active-whyPinC");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refWhyPin.current) {
      observer.observe(refWhyPin.current);
    }
    return () => {
      if (refWhyPin.current) {
        observer.unobserve(refWhyPin.current);
      }
    };
  }, [refWhyPin]);

  const refHome0 = useRef(null);
  const refHome1 = useRef(null);
  const refHome2 = useRef(null);
  const refHome3_1 = useRef(null);
  const refHome3_2 = useRef(null);
  const refHome3_3 = useRef(null);
  const refHome4 = useRef(null);
  const refHome5 = useRef(null);
  const refHome6 = useRef(null);
  const refHome7 = useRef(null);
  const refHome8 = useRef(null);
  const refHome9 = useRef(null);
  const refHome10 = useRef(null);
  const refHome11 = useRef(null);
  const refHome12 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home1");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome0.current) {
      observer.observe(refHome0.current);
    }
    return () => {
      if (refHome0.current) {
        observer.unobserve(refHome0.current);
      }
    };
  }, [refHome0]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home1");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome1.current) {
      observer.observe(refHome1.current);
    }
    return () => {
      if (refHome1.current) {
        observer.unobserve(refHome1.current);
      }
    };
  }, [refHome1]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home2");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome2.current) {
      observer.observe(refHome2.current);
    }
    return () => {
      if (refHome2.current) {
        observer.unobserve(refHome2.current);
      }
    };
  }, [refHome2]);

  useEffect(() => {
    const cards = document.querySelector(".locoCardRight1");
    const cardsDown = document.querySelector(".locoCardDown1");
    cards.style.marginLeft = "-350px";
    cards.style.transition = "0.99s ease-out ";
    cardsDown.style.marginTop = "-200px";
    cardsDown.style.transition = "0.99s ease-out ";

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home3_1");
          cards.style.marginLeft = "-180px";
          cardsDown.style.marginTop = "-100px";
        } else {
          cards.style.marginLeft = "-350px";
          cardsDown.style.marginTop = "-200px";
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome3_1.current) {
      observer.observe(refHome3_1.current);
    }
    return () => {
      if (refHome3_1.current) {
        observer.unobserve(refHome3_1.current);
      }
    };
  }, [refHome3_1]);

  useEffect(() => {
    const cards = document.querySelector(".locoCardRight2");
    const cardsDown = document.querySelector(".locoCardDown2");
    cards.style.marginLeft = "-350px";
    cards.style.transition = "0.99s ease-out ";
    cardsDown.style.marginTop = "-200px";
    cardsDown.style.transition = "0.99s ease-out ";

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home3_1");
          cards.style.marginLeft = "-180px";
          cardsDown.style.marginTop = "-100px";
        } else {
          cards.style.marginLeft = "-350px";
          cardsDown.style.marginTop = "-200px";
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome3_2.current) {
      observer.observe(refHome3_2.current);
    }
    return () => {
      if (refHome3_2.current) {
        observer.unobserve(refHome3_2.current);
      }
    };
  }, [refHome3_2]);

  useEffect(() => {
    const cards = document.querySelector(".locoCardRight3");
    const cardsDown = document.querySelector(".locoCardDown3");
    cards.style.marginLeft = "-350px";
    cards.style.transition = "0.99s ease-out ";
    cardsDown.style.marginTop = "-200px";
    cardsDown.style.transition = "0.99s ease-out ";

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home3_3");
          cards.style.marginLeft = "-180px";
          cardsDown.style.marginTop = "-100px";
        } else {
          cards.style.marginLeft = "-350px";
          cardsDown.style.marginTop = "-200px";
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome3_3.current) {
      observer.observe(refHome3_3.current);
    }
    return () => {
      if (refHome3_3.current) {
        observer.unobserve(refHome3_3.current);
      }
    };
  }, [refHome3_3]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home4");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome4.current) {
      observer.observe(refHome4.current);
    }
    return () => {
      if (refHome4.current) {
        observer.unobserve(refHome4.current);
      }
    };
  }, [refHome4]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home5");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome5.current) {
      observer.observe(refHome5.current);
    }
    return () => {
      if (refHome5.current) {
        observer.unobserve(refHome5.current);
      }
    };
  }, [refHome5]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home6");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome6.current) {
      observer.observe(refHome6.current);
    }
    return () => {
      if (refHome6.current) {
        observer.unobserve(refHome6.current);
      }
    };
  }, [refHome6]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home7");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome7.current) {
      observer.observe(refHome7.current);
    }
    return () => {
      if (refHome7.current) {
        observer.unobserve(refHome7.current);
      }
    };
  }, [refHome7]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home8");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome8.current) {
      observer.observe(refHome8.current);
    }
    return () => {
      if (refHome8.current) {
        observer.unobserve(refHome8.current);
      }
    };
  }, [refHome8]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home9");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome9.current) {
      observer.observe(refHome9.current);
    }
    return () => {
      if (refHome9.current) {
        observer.unobserve(refHome9.current);
      }
    };
  }, [refHome1]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home10");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome10.current) {
      observer.observe(refHome10.current);
    }
    return () => {
      if (refHome10.current) {
        observer.unobserve(refHome10.current);
      }
    };
  }, [refHome10]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home11");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome11.current) {
      observer.observe(refHome11.current);
    }
    return () => {
      if (refHome11.current) {
        observer.unobserve(refHome11.current);
      }
    };
  }, [refHome11]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDivSection("home12");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome12.current) {
      observer.observe(refHome12.current);
    }
    return () => {
      if (refHome12.current) {
        observer.unobserve(refHome12.current);
      }
    };
  }, [refHome12]);

  useEffect(() => {
    const text1 = document.querySelector(".homeText1");
    const text2 = document.querySelector(".homeText2");
    const text3_1 = document.querySelector(".homeText3_1");
    const text3_2 = document.querySelector(".homeText3_2");
    const text3_3 = document.querySelector(".homeText3_3");
    const text4 = document.querySelector(".homeText4");
    const text5 = document.querySelector(".homeText5");
    const text6 = document.querySelector(".homeText6");
    const text7 = document.querySelector(".homeText7");
    const text8 = document.querySelector(".homeText8");
    const text9 = document.querySelector(".homeText9");
    const text10 = document.querySelector(".homeText10");
    const text11 = document.querySelector(".homeText11");
    const text12 = document.querySelector(".homeText12");
    const sideMenu = document.querySelector(".sideColorMenu");

    const topSection = document.querySelector(".homeTop");

    sideMenu.style.opacity = 0;

    if (divSection == "home1") {
      text1.style.display = "block";
      topSection.style.opacity = 1;
    } else {
      text1.style.display = "none";
    }
    if (divSection == "home2") {
      text2.style.display = "block";
      topSection.style.opacity = 1;
      sideMenu.style.opacity = 1;
    } else {
      text2.style.display = "none";
    }
    if (divSection == "home3_1") {
      text3_1.style.display = "block";
      // topSection.style.opacity = 0;
      sideMenu.style.opacity = 1;
    } else {
      text3_1.style.display = "none";
    }
    if (divSection == "home3_2") {
      text3_2.style.display = "block";
      // topSection.style.opacity = 0;
      sideMenu.style.opacity = 1;
    } else {
      text3_2.style.display = "none";
    }
    if (divSection == "home3_3") {
      text3_3.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text3_3.style.display = "none";
    }

    if (divSection == "home5") {
      text5.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text5.style.display = "none";
    }
    if (divSection == "home6") {
      text6.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text6.style.display = "none";
    }
    if (divSection == "home7") {
      text7.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text7.style.display = "none";
    }
    if (divSection == "home8") {
      text8.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text8.style.display = "none";
    }
    if (divSection == "home9") {
      text9.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text9.style.display = "none";
    }
    if (divSection == "home10") {
      text10.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text10.style.display = "none";
    }
    if (divSection == "home11") {
      text11.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text11.style.display = "none";
    }
    if (divSection == "home12") {
      text12.style.display = "block";
      sideMenu.style.opacity = 1;
    } else {
      text12.style.display = "none";
    }
  }, [divSection]);


  useEffect(() => {
    const horizontalScroll = () => {
      const sticky = document.querySelector(".sticky");
      const stickyParent = document.querySelector(".sticky-parent");

      const scrollWidth = sticky.scrollWidth;
      const verticalScrollHeight =
        stickyParent.getBoundingClientRect().height -
        sticky.getBoundingClientRect().height;

      const stickyPosition = sticky.getBoundingClientRect().top;
      if (stickyPosition > 1) {
        return;
      } else {
        const scrolled = stickyParent.getBoundingClientRect().top;
        sticky.scrollLeft =
          (scrollWidth / verticalScrollHeight) * -scrolled * 0.75;
      }
    };

    document.addEventListener("scroll", horizontalScroll);

    return () => {
      document.removeEventListener("scroll", horizontalScroll);
    };
  }, []);

  const advanceReerchLab = useRef(null);

  useEffect(() => {
    const image = document.querySelector(".svgDrow");

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          image.classList.add("active");
        } else {
          image.classList.remove("active");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (advanceReerchLab.current) {
      observer.observe(advanceReerchLab.current);
    }
    return () => {
      if (advanceReerchLab.current) {
        observer.unobserve(advanceReerchLab.current);
      }
    };
  }, [advanceReerchLab]);

  useEffect(() => {
    const div = document.querySelector("#moving");
    div.addEventListener("mousemove", parallax);
    function parallax(e) {
      document.querySelectorAll(".testiImg").forEach((el) => {
        var moving_value = el.getAttribute("data-value");
        var x = (e.clientX * moving_value) / 250;
        var y = (e.clientY * moving_value) / 250;
        el.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
      });
    }
  });

  useEffect(() => {
    const div = document.querySelector(".whyPin");
    div.addEventListener("mousemove", parallax);
    function parallax(e) {
      document.querySelectorAll(".testiImg2").forEach((el) => {
        var moving_value = el.getAttribute("data-value");
        var x = (e.clientX * moving_value) / 250;
        var y = (e.clientY * moving_value) / 250;
        el.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
      });
    }
  });

  const navigate = useNavigate();

  return (
    <div style={{ position: "relative" }}>
      <div class="sideColorMenu">
        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "white" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText1"> PLASTIC </p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "black" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home2"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText2 text-white"> Products </p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "blue" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home3_1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText3_1 text-white">color</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "blue" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home3_2"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText3_2 text-white">color</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "blue" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home3_3"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText3_3 text-white">color</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "white" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home5"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText5">About</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "#1772C5" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home6"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText6 text-white">Industries </p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "#cccccc" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home7"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText7">Compatibility</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "white" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home8"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText8">Lab</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "#73FAC9" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home9"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText9">Why</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "Black" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home10"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText10 text-white">Testimonials</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "white" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home11"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText11">Partners</p>
          </Link>
        </div>

        <div
          class="px-0 sideMainDiv"
          style={{ width: "fit-content", backgroundColor: "#8FEC85" }}
        >
          <Link
            activeClass="active"
            style={{ textDecoration: "none", color: "black" }}
            to="home12"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <p class="homeText12">Business</p>
          </Link>
        </div>
      </div>

      <div>
        <div ref={refHome0}> </div>
        <div class="homeTop home1" ref={refHome1}>
          <Scrolled data={data} />
        </div>

        <div class="homeColor">
          <div className="sticky-parent">
            <div className="sticky">
              <div className="horizontal">
                {" "}
                {/*Horizantal conatiner with display flex*/}
                {/*Content Start*/}
                <div
                  className="dim home2"
                  id="moving"
                  ref={refHome2}
                  style={{
                    backgroundColor: "black",
                    minWidth: "100vw",
                    color: "white",
                    position: "relative",
                  }}
                >
                  <div>
                    <p class="display-1 fw-bold textLarg">SOLUTIONS</p>
                    <p class="display-1 textLarg">WE OFFER </p>
                  </div>
                  <div
                    style={{ position: "absolute" }}
                    class="testiImg"
                    data-value={8}
                  >
                    <img
                      data-aos="zoom-in"
                      src={require("../../images/home/plasticPart.png")}
                      style={{ width: "100%", height: "100%" }}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="dim home3_1"
                  ref={refHome3_1}
                  style={{ backgroundColor: "blue" }}
                >
                  <div class="homeOfferPC">
                    <div
                      class="m-auto d-flex align-items-center"
                      style={{ minHeight: "495px", minWidth: "600px" }}
                    >
                      <div
                        class=""
                        style={{
                          width: "350px",
                          height: "450px",
                          borderRadius: "50px",
                          zIndex: 10,
                        }}
                      >
                        <img
                          src={require("../../images/home/pre-color-resin.jpg")}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center locoCardRight1"
                        style={{
                          width: "450px",
                          background: "#1772C5",
                          height: "450px",
                          borderRadius: "50%",
                          zIndex: 1,
                        }}
                      >
                        <div
                          class="text-white p-2 text-start "
                          style={{ marginLeft: "150px" }}
                        >
                          <p class="fs-4 fw-bold">
                            Pre <br /> colored <br /> resin
                          </p>
                          <br />
                          <p>
                            Illuminate Your Products <br /> with Plastic
                            Innovation's <br /> Pre-Color Resin Service
                          </p>
                          <p
                            onClick={() => navigate("/pre-colored-resin")}
                            style={{ cursor: "pointer" }}
                          >
                            <span class="fs-1">
                              <CgArrowLongRight />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="homeOfferMoile" style={{ marginLeft: "150px" }}>
                    <div
                      class="m-auto  align-items-center"
                      style={{ minHeight: "495px", minWidth: "600px" }}
                    >
                      <div
                        class=""
                        style={{
                          width: "250px",
                          height: "300px",
                          borderRadius: "50px",
                          zIndex: 10,
                          margin: "0 25px",
                        }}
                      >
                        <img
                          src={require("../../images/home/pre-color-resin.jpg")}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        class=" locoCardDown1"
                        style={{
                          width: "300px",
                          background: "#1772C5",
                          height: "300px",
                          borderRadius: "50%",
                          zIndex: "-88888",
                        }}
                      >
                        <div style={{ height: "90px" }}></div>
                        <div class="text-white p-2 text-center col-10 m-auto ">
                          <p class="fs-4 fw-bold">Pre colored resin</p>
                          <br />
                          <p>
                            Illuminate Your Products with Plastic Innovation's
                            Pre-Color Resin Service
                          </p>
                          <p>
                            <span
                              class="fs-1"
                              onClick={() => navigate("/pre-colored-resin")}
                              style={{ cursor: "pointer" }}
                            >
                              <CgArrowLongRight />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="dim home3_2"
                  ref={refHome3_2}
                  style={{ backgroundColor: "blue" }}
                >
                  <div class="homeOfferPC">
                    <div
                      class="m-auto d-flex align-items-center"
                      style={{ minHeight: "495px", minWidth: "600px" }}
                    >
                      <div
                        class=""
                        style={{
                          width: "350px",
                          height: "450px",
                          borderRadius: "50px",
                          zIndex: 10,
                          objectFit: "cover",
                        }}
                      >
                        <img
                          src={require("../../images/home/color-concentrate.jpg")}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center locoCardRight2"
                        style={{
                          width: "450px",
                          background: "#1772C5",
                          height: "450px",
                          borderRadius: "50%",
                          zIndex: 1,
                        }}
                      >
                        <div
                          class="text-white p-2 text-start "
                          style={{ marginLeft: "150px" }}
                        >
                          <p class="fs-4 fw-bold">
                            Color <br /> Concentrate
                          </p>
                          <br />
                          <p>
                            Unleash Brilliance with <br /> Plastic Innovation's{" "}
                            <br /> Color Concentrate Service
                          </p>
                          <p
                            onClick={() => navigate("/color-consentrate")}
                            style={{ cursor: "pointer" }}
                          >
                            <span class="fs-1">
                              <CgArrowLongRight />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="homeOfferMoile">
                    <div
                      class="m-auto  align-items-center"
                      style={{ minHeight: "495px", minWidth: "600px" }}
                    >
                      <div
                        class=""
                        style={{
                          width: "250px",
                          height: "300px",
                          borderRadius: "50px",
                          zIndex: 10,
                          margin: "0 25px",
                        }}
                      >
                        <img
                          src={require("../../images/home/color-concentrate.jpg")}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        class=" locoCardDown2"
                        style={{
                          width: "300px",
                          background: "#1772C5",
                          height: "300px",
                          borderRadius: "50%",
                          zIndex: "-88888",
                        }}
                      >
                        <div style={{ height: "90px" }}></div>
                        <div class="text-white p-2 text-center col-10 m-auto ">
                          <p class="fs-4 fw-bold"> Color Concentrate </p>
                          <br />
                          <p>
                            Unleash Brilliance with Plastic Innovation's Color
                            Concentrate Service
                          </p>
                          <p>
                            <span
                              class="fs-1"
                              onClick={() => navigate("/color-consentrate")}
                              style={{ cursor: "pointer" }}
                            >
                              <CgArrowLongRight />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="dim home3_3"
                  ref={refHome3_3}
                  style={{ backgroundColor: "blue" }}
                >
                  <div class="homeOfferPC">
                    <div
                      class="m-auto d-flex align-items-center"
                      style={{ minHeight: "495px", minWidth: "600px" }}
                    >
                      <div
                        class=""
                        style={{
                          width: "350px",
                          height: "450px",
                          borderRadius: "50px",
                          zIndex: 10,
                        }}
                      >
                        <img
                          src={require("../../images/home/loco1.png")}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center locoCardRight3"
                        style={{
                          width: "450px",
                          background: "#1772C5",
                          height: "450px",
                          borderRadius: "50%",
                          zIndex: 1,
                        }}
                      >
                        <div
                          class="text-white p-2 text-start "
                          style={{ marginLeft: "150px" }}
                        >
                          <p class="fs-4 fw-bold">
                            Additives & <br /> Minerals <br />
                            compounding
                          </p>
                          <br />
                          <p>
                            Supercharge Your Plastics <br /> with Plastic
                            Innovation's <br />
                            Additives & Minerals <br /> Compounding Service
                          </p>
                          <p
                            onClick={() => navigate("/additives-minerals")}
                            style={{ cursor: "pointer" }}
                          >
                            <span class="fs-1">
                              <CgArrowLongRight />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="homeOfferMoile">
                    <div
                      class="m-auto  align-items-center"
                      style={{ minHeight: "495px", minWidth: "600px" }}
                    >
                      <div
                        class=""
                        style={{
                          width: "250px",
                          height: "300px",
                          borderRadius: "50px",
                          zIndex: 10,
                          margin: "0 25px",
                        }}
                      >
                        <img
                          src={require("../../images/home/loco1.png")}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        class="locoCardDown3"
                        style={{
                          width: "300px",
                          background: "#1772C5",
                          height: "300px",
                          borderRadius: "50%",
                          zIndex: "-88888",
                        }}
                      >
                        <div style={{ height: "90px" }}></div>
                        <div class="text-white p-2 text-center col-10 m-auto ">
                          <p class="fs-4 fw-bold">Pre colored resin</p>
                          <br />
                          <p>
                            Supercharge Your Plastics with Plastic Innovation's
                            Additives & Minerals Compounding Service
                          </p>
                          <p>
                            <span
                              class="fs-1"
                              onClick={() => navigate("/additives-minerals")}
                              style={{ cursor: "pointer" }}
                            >
                              <CgArrowLongRight />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row col-12 m-0 d-flex justify-content-between home5"
            ref={refHome5}
          >
            <div class="col-lg-6 col-12  d-flex ">
              <div class="p-3 col-md-11 col-12 text-start m-auto ">
                <p class="display-4 d-flex flex-wrap">
                  <span class="fw-semibold" data-aos="fade-up">
                    Welcome to{" "}
                  </span>{" "}
                  <span>
                    {" "}
                    <button
                      data-aos="fade-up"
                      class="plasticWord display-4 px-2 fw-bold mx-2"
                    >
                      Plastic
                    </button>
                  </span>{" "}
                  <span class="fw-bold" data-aos="fade-up">
                    Innovations
                  </span>
                </p>
                <p class="fs-4" data-aos="fade-up">
                  A whole lot of things have happened in plastics since the last
                  half of the century. Advances into new areas of applications
                  have been more and more frequent and consequential.
                  Engineering and processing technology have advanced
                  tremendously.{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-12 m-0 p-0">
              <div class="col-12 m-0 p-0">
                <img
                  style={{ height: "100%", width: "100%", objectFit: "fill" }}
                  data-aos="zoom-in"
                  src={require("../../images/home/home4.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div ref={refHome6} class="home6">
            <IndustriesSlider />
          </div>

          <div
            class="row p-2 m-auto home7"
            style={{ width: "98%" }}
            ref={refHome7}
          >
            <div class="col-lg-5 col-12 text-start rounded-5 p-2 ">
              <div
                class="p-2 rounded-5"
                style={{
                  height: "100%",
                  backgroundImage: `url(${require("../../images/home/home12.png")})`,
                  backgroundSize: "cover",
                  position: "relative",
                }}
              >
                <div class="m-5">
                  <p class="text-white fs-1 fw-bold " data-aos="fade-up">
                    Compatibility
                    <br /> of materials
                  </p>
                  <div style={{ height: "100px" }}></div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "-100px",
                    width: "250px",
                    left: "10%",
                    zIndex: "1",
                  }}
                >
                  <ScrolledImg data={data} />
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-12">
              <Compatibility1 data={compatibilitySection()} />
            </div>
          </div>

          <div class="home8">
            <div class="mt-5 col-11 m-auto " ref={refHome8}>
              <LeftSlide />
              <div class="row my-5">
                <div class="col-md-6 col-12 text-start" data-aos="fade-up">
                  <div class="col-10">
                    <p class="display-4 fw-semibold">
                      Redefining <br />
                      Plastic Color Solutions
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-12 text-start" data-aos="fade-up">
                  <p class="fs-5">
                    Discover the power of Plastic Innovation's Advanced Research
                    Lab, where visionary scientists and color experts push the
                    boundaries of plastic color. We create captivating shades
                    and effects, elevating your products with vibrant
                    brilliance. Through innovative additives and compounds, we
                    enhance durability and functionality. Experience the future
                    of plastic color with our customized solutions. Unleash your
                    product's potential today.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div ref={refHome9} class="home9">
            <div class="whyPin" ref={refWhyPin}>
              <div class="whyPinC"></div>
              <div class="whyPinI">
                <div style={{ height: "50px" }}>
                  <br /> <br />
                </div>
                <div>
                  <p
                    class="display-3 py-5 m-auto"
                    data-aos="fade-up"
                    style={{ maxWidth: "600px" }}
                  >
                    <span class="fw-semibold">Why</span>{" "}
                    <span class="fw-bold">Plastic Innovation</span>
                  </p>
                </div>
                <div class="col-md-8 col-12 m-auto">
                  <WhyPlasticInnovation data={whyPlasticInnovations()} />
                </div>
                <br />
              </div>

              <div
                style={{ position: "absolute", top: "30%", left: "0" }}
                class="testiImg2"
                data-value={8}
              >
                <img
                  data-aos="zoom-in"
                  src={require("../../images/home/plasticPart2.png")}
                  style={{ width: "90%", height: "100%" }}
                  alt=""
                />
              </div>
            </div>
            {/* <PlasticInovation /> */}
          </div>

          <div
            class="bg-black home10"
            style={{
              width: "99%",
              margin: "auto",
              borderRadius: "0px 0px 25px 25px",
            }}
            ref={refHome10}
          >
            <p class="display-3 py-5 text-white" data-aos="zoom-in">
              <span class="fw-semibold">Our</span>{" "}
              <span class="fw-bold">Testimonials</span>
            </p>
            <div class="py-4">
              <Testimonials data={testimonial()} />
            </div>
          </div>

          <div class="mt-5 home11" ref={refHome11}>
            <p class="display-3 py-3">
              <span class="fw-semibold">Our</span>{" "}
              <span class="fw-bold">Partners</span>
            </p>
            <div class="py-4 partner">
              <OurPartners data={partner()} />
            </div>
          </div>

          <div ref={refHome12}>
            <FooterFirst />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
