import React from "react";
import "./ourService.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { OurServiceBenifit, OurServiceCart } from "./home/HomeCurocel";
import FooterFirst from "./FooterFirst";

const ColorConcentrate = [
  "Captivating colors: Our expertly crafted color concentrates create vibrant hues that leave a lasting impression.",
  "Seamless integration: Our service seamlessly integrates into your manufacturing process, saving time and resources.",
  "Personalized consultations: We provide tailored consultations to understand your vision and deliver customized color solutions.",
  "Exceptional customer service: Our dedicated team ensures your satisfaction with high-quality color concentrates.",
  " Industry differentiation: Stand out from competitors with captivating colors that enhance your brand and attract customers.",
];

 

 

 
const ourValue = [
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Captivating Masterpieces: Plastic Innovation's Color Concentrate Service creates vibrant works of art.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Seamless Integration: Effortlessly enhance your plastic products with our efficient service.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Personalized Consultations: We understand your vision and bring it to life.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Exceptional Customer Service: Experience our commitment to your satisfaction.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Industry Impact: Stand out with plastic products that leave a colorful mark.",
  },
  {
    icon: `${require("./img/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Shorter Lead Time: We value your time. Streamlined processes for tighter deadlines.",
  },
];

const OurValues = () => {
  const arr = [];
  for (let i = 0; i < ourValue.length; i++) {
    arr.push(
      <div class="col-12 text-start p-3" data-aos="fade-up">
        <img src={ourValue[i].icon} style={{ width: "60px" }} alt="" />
        {/* <p class='fw-bold fs-5 my-2'>Lorem ipsum dolor  enim ad minim</p> */}
        <p class="my-3">{ourValue[i].text}</p>
      </div>
    );
  }
  return arr;
};

const ColorConc = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <div id="colorConc">
      <div style={{ height: "80px" }}></div>
      <div class="text-start col-11 m-auto">
        <p class="display-1 fw-bold my-3" data-aos="fade-up">
          Color <br /> Concentrate
        </p>
      </div>
      <div
        class="row col-11 m-auto "
        style={{
          borderTop: "1px solid",
        }}
      >
        <div class="col-lg-4 col-12 " style={{ borderRight: "1px solid" }}>
          <div class="col-md-11 col-12 text-start my-4">
            <p class="fw-bold fs-3" data-aos="fade-up">
              Unleash Brilliance with Plastic Innovation's Color Concentrate
              Service
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="col-md-12 col-12 text-start my-4 row">
            <div class="col-lg-1 col-12"></div>
            <div class="col-lg-11 col-12">
              <p
                class="text-secandry text22"
                data-aos="fade-up"
                style={{ textAlign: "justify" }}
              >
                Plastic Innovation offers a cutting-edge Color Concentrate
                Service that transforms plastic products into captivating works
                of art. Our expertly crafted color concentrates deliver vibrant
                hues that leave a lasting impression. With seamless integration
                into your manufacturing process, our efficient service saves
                time and resources. Experience personalized consultations and
                exceptional customer service as we bring your vision to life.
                Elevate your plastic products with Plastic Innovation's Color
                Concentrate Service and leave a colorful mark on your industry.
                Contact us today for brilliance unleashed.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "70vh" }}>
        <img
          src={require("../images/home/color-concentrate2.jpg")}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt=""
        />
      </div>

      <div>
        <div class="col-11 m-auto my-4" data-aos="fade-up">
          <p class="display-5 m-auto text-start fw-normal">
            Plastic Innovation's Color Concentrate Service offers the following
            advantages{" "}
          </p>
        </div>
        <div class="col-11 m-auto">
          <ul class="text-start">
            {ColorConcentrate.map((el) => {
              return (
                <li style={{ listStyleType: "circle" }} data-aos="fade-up">
                  <p class="fs-5 text-secondary">{el}</p>
                </li>
              );
            })}
          </ul>
          <p class="text-start fs-4 fw-semibold " data-aos="fade-up">
            Elevate your plastic products with Plastic Innovation's Color
            Concentrate Service. Contact us to unlock the power of captivating
            colors today.
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <div class="col-12">
        <img
          src={require("../images/home/line2.png")}
          style={{ transform: "scaleX(-1)", width: "100%" }}
          alt=""
        />
      </div>
      <div style={{ position: "relative", top: "-160px" }}>
        <div class="ourservicBenefit text-white">
          <div class="ourservicBenefitHide" style={{ height: "150px" }}></div>
          <div class="col-12 m-auto text-start ">
            <div class="col-11 m-auto">
              <br />
              <p class="display-3 mb-5" data-aos="fade-up">
                {" "}
                <span>Our</span> <span class="fw-bold">Values</span>
              </p>
            </div>
            <br />
            <div class="row col-12  ">
              <div class="col-11 m-auto ">
                <OurServiceBenifit data={OurValues()} />
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>

      <div class="ourServisFooter">
        <br />
        <FooterFirst />
        <br />
      </div>
    </div>
  );
};

export default ColorConc;
