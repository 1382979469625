import React from 'react'
import { useEffect } from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { OfferMini } from './HomeCurocel';
import AOS from 'aos';
import 'aos/dist/aos.css';


const offer =[
  {
    img:`${require('../../images/home/loco1.png')}`,
    title:'Precolored resin',
    text:'PII provides innovative polymer materials, application development, and custom compounds',
    link:''
  },
  {
    img:`${require('../../images/home/loco1.png')}`,
    title:'Precolored resin',
    text:'PII provides innovative polymer materials, application development, and custom compounds',
    link:''
  },
  {
    img:`${require('../../images/home/loco1.png')}`,
    title:'Precolored resin',
    text:'PII provides innovative polymer materials, application development, and custom compounds',
    link:''
  },
]
const offers = ()=>{
  let arr = []
  for(let i = 0 ; i < offer.length ;i++){
    arr.push(
      <div class=''> 
      <div style={{width:'250px',height:'260px',margin:'auto',borderRadius:'50px',objectFit:'cover'}}>
      <img src={offer[i].img} style={{width:'100%',height:'100%',borderRadius:'50px',}} alt="" />
      </div>

      <div  style={{width:'300px',height:'300px',background:'#1772C5',borderRadius:'50%',margin:"auto",marginTop:'-100px',display:'block',zIndex:'9999'}}>
        
        <div class='col-10 m-auto  text-white'>
          <div style={{height:'100px'}}></div>
          <p class='fs-5 fw-bold my-2' >{offer[i].title}</p> 
          <p >{offer[i].text} </p>
          <p><span class='fs-1'><CgArrowLongRight/></span></p>
        </div>

      </div>

    </div>
    )
  }
  return arr;
}




const Offer = () => {

  useEffect(()=>{
    AOS.init({duration: 1500});
  },)

  useEffect(()=>{
    const div = document.querySelector("#moving")
    div.addEventListener('mousemove', parallax);
    function parallax(e){
        document.querySelectorAll(".testiImg").forEach((el)=>{ 
           var moving_value = el.getAttribute("data-value");
           var x = (e.clientX * moving_value)/250;
           var y = (e.clientY * moving_value)/250;
           el.style.transform = "translateX("+x+"px) translateY("+y+"px)";   
       })  
    }   
  })
  return (
    <div>
        <div className="dim home2 rounded-5" id='moving'   style={{backgroundColor: 'black',minWidth:'100vw',height:'50vh', color:'white',position:'relative'}} >
                        <div > 
                            <p class='display-1 fw-bold textLarg'>SOLUTIONS</p>
                            <p class='display-1 textLarg'>WE OFFERS </p>
                        </div>
                        <div style={{position:'absolute'}} class='testiImg'   data-value={8}  >
                          <img data-aos="zoom-in"  src={require('../../images/home/plasticPart.png')} style={{width:'100%',height:'100%'}} alt="" />
                        </div>
        </div>
        <div class='' style={{background:'#46A5FB',marginTop:'-30px'}}>
          <br />
          <br />
          <br />
           <OfferMini data={offers()}/>
          <br /> 
          <br />
        </div>

    </div>
  )
}

export default Offer