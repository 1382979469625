import React, { useState } from "react";
import "../Style.css";
import { TbPointFilled } from "react-icons/tb";

const PrivacyPolicy = ({ data }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        top: "0",
        background: "rgba(0,0,0,.8)",
        zIndex: "2",
      }}
    >
      <br />
      <br />
      <div
        class="col-lg-8 col-11 m-auto"
        style={{
          height: "85vh",
          overflowY: "auto",
        }}
      >
        <div class="text-end">
          <p style={{ cursor: "pointer" }}>
            <i class="bi bi-x-lg text-white" onClick={() => data(false)}></i>
          </p>
          <br />
        </div>
        <div class="" style={{ background: "#ffffff" }}>
          <div class="col-11 m-auto ">
            <br />
            <p class="fw-bold fs-3 text-start">Privacy Policy</p>
            <div class="text-start my-3">
              <p style={{ textAlign: "justify" }}>
                At Plastic Innovations, we respect and value your privacy. This
                Privacy Policy explains how we collect, use, disclose, and
                protect your personal information when you visit our website
                (http://plasticinnovations.com) and interact with our services.
                By accessing or using our website, you agree to the terms
                outlined in this Privacy Policy.
              </p>

              <p class="fw-semibold mt-3">1. Information We Collect:</p>
              <div class="mx-2 d-flex gap-1">
                <p>
                  <TbPointFilled />
                </p>
                <p style={{ textAlign: "justify" }}>
                  Personal Information: When you submit an inquiry or contact us
                  through our website, we may collect certain personal
                  information, such as your name, email address, phone number,
                  and any other information you provide voluntarily.
                </p>
              </div>
              <div class="mx-2 d-flex gap-1">
                <p>
                  <TbPointFilled />
                </p>
                <p style={{ textAlign: "justify" }}>
                  Automatically Collected Information: We may automatically
                  collect certain non-personal information about your visit to
                  our website, including your IP address, browser type,
                  referring/exit pages, and operating system. This information
                  is collected using cookies and similar technologies to enhance
                  your browsing experience.{" "}
                </p>
              </div>

              <p class="fw-semibold mt-3">2. Use of Information:</p>
              <div class="mx-2 d-flex gap-1">
                <p>
                  <TbPointFilled />
                </p>
                <p style={{ textAlign: "justify" }}>
                  Personal Information: We use the personal information you
                  provide solely for the purpose of responding to your inquiry
                  or communication. We do not sell or disclose your personal
                  information to third parties unless required by law.{" "}
                </p>
              </div>
              <div class="mx-2 d-flex gap-1">
                <p>
                  <TbPointFilled />
                </p>
                <p style={{ textAlign: "justify" }}>
                  {" "}
                  Automatically Collected Information: The non-personal
                  information we collect automatically is used for statistical
                  purposes, website maintenance, and improving our services.
                  This information helps us understand how visitors interact
                  with our website, enabling us to enhance its functionality and
                  user experience.
                </p>
              </div>

              <p class="fw-semibold mt-3">
                3. Cookies and Tracking Technologies:{" "}
              </p>
              <div class="mx-2 d-flex gap-1">
                <p>
                  <TbPointFilled />
                </p>
                <p style={{ textAlign: "justify" }}>
                  {" "}
                  Cookies: Our website uses cookies to store certain information
                  about your preferences and activities. These cookies allow us
                  to personalise your experience and provide you with relevant
                  content and advertisements. You have the option to disable
                  cookies through your browser settings, although this may
                  affect the functionality of our website.{" "}
                </p>
              </div>
              <div class="mx-2 d-flex gap-1">
                <p>
                  <TbPointFilled />
                </p>
                <p style={{ textAlign: "justify" }}>
                  Third-Party Tracking: We may use third-party analytics
                  services, such as Google Analytics, to collect and analyse
                  non-personal information about your website usage. These
                  third-party services may use cookies and similar technologies
                  to track your interactions with our website and provide us
                  with reports and insights.
                </p>
              </div>

              <div class="mx-2 d-flex gap-1 mt-3">
                <p class="fw-semibold">4.</p>
                <p class="fw-semibold " style={{ textAlign: "justify" }}>
                  {" "}
                  Data Security: We implement reasonable security measures to
                  protect your personal information from unauthorised access,
                  disclosure, alteration, or destruction. However, please note
                  that no method of transmission over the internet or electronic
                  storage is 100% secure, and we cannot guarantee absolute
                  security of your data.
                </p>
              </div>
              <div class="mx-2 d-flex gap-1 mt-3">
                <p class="fw-semibold">5.</p>
                <p class="fw-semibold " style={{ textAlign: "justify" }}>
                  {" "}
                  Children's Privacy: Our website is not intended for children
                  under the age of 13. We do not knowingly collect personal
                  information from individuals under 13 years of age. If we
                  become aware that we have inadvertently collected personal
                  information from a child under the age of 13, we will take
                  steps to delete such information promptly.
                </p>
              </div>
              <div class="mx-2 d-flex gap-1 mt-3">
                <p class="fw-semibold">6.</p>
                <p class="fw-semibold " style={{ textAlign: "justify" }}>
                  {" "}
                  Changes to this Privacy Policy: We reserve the right to update
                  or modify this Privacy Policy at any time. The updated policy
                  will be effective upon posting on our website. It is your
                  responsibility to review this Privacy Policy periodically to
                  stay informed about any changes.
                </p>
              </div>
              <div class="mx-2 d-flex gap-1 mt-3">
                <p class="fw-semibold">7.</p>
                <p class="fw-semibold " style={{ textAlign: "justify" }}>
                  {" "}
                  Contact Us: If you have any questions, concerns, or requests
                  regarding this Privacy Policy or our data practices, please
                  contact us at:
                </p>
              </div>

              <p class='mt-3  '>Plastic Innovations Address: 10513 San Sevaine WayJurupa Valley, CA USA 91752</p>
              <p class='mt-3 fw-semibold'> <span class='fw-bold'>Email : </span>info@Plasticinnovations.com</p>
              <p class='mt-3  '>By using our website and submitting your inquiry, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.</p>
            </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-primary rounded-5 fw-semibold p-3 px-5 my-3" onClick={()=> data(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
