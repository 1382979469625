import React, { useEffect, useState } from "react";
import "./Application.css";
import FooterFirst from "../FooterFirst";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  BsArrowRightCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";

const data = [
  {
    img: require("./image/airo.png"),
    title: "Airline",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    bgcolor: "#F9F871",
  },
  {
    img: require("./image/book.png"),
    title: "Books",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    bgcolor: "#8FEC85",
  },
  {
    img: require("./image/airo.png"),
    title: "Airline",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    bgcolor: "#F9F871",
  },
  {
    img: require("./image/book.png"),
    title: "Books",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    bgcolor: "#8FEC85",
  },
  {
    img: require("./image/airo.png"),
    title: "Airline",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    bgcolor: "#F9F871",
  },
  {
    img: require("./image/book.png"),
    title: "Books",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    bgcolor: "#8FEC85",
  },
];

const faq = [
  {
    que: "What is PII and why is it the best in business?",
    ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c",
  },
  {
    que: "What is your quis nostrum exercitationem ullam corporis?",
    ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c",
  },
  {
    que: "How do you consequuntur magni dolores eos qui?",
    ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c",
  },
  {
    que: "Architecto beatae vitae dicta sunt explicabo. Nemo?",
    ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c",
  },
  {
    que: "What is PII and why is it the best in business?",
    ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c",
  },
];

const Application = () => {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);
  const [faq6, setFaq6] = useState(false);
  const [faq7, setFaq7] = useState(false);
  const [faq8, setFaq8] = useState(false);

  console.log(faq1);

  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  return (
    <div>
      <div style={{ height: "80px" }}></div>
      <div class="text-start col-11 m-auto">
        <p class="display-1 fw-bold my-3" data-aos="fade-up">
          Our Application
        </p>
      </div>
      <div class="row col-11 m-auto " style={{ borderTop: "1px solid" }}>
        <div class="col-lg-4 col-12 " style={{ borderRight: "1px solid" }}>
          <div class="col-md-11 col-12 text-start my-4">
            <p class="fw-bold fs-3" data-aos="fade-up">
              PII is proud to offer innovative ideas and solutions to our
              customer base.{" "}
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="col-md-10 col-12 text-start my-4 row">
            <div class="col-lg-1 col-12"></div>
            <div class="col-lg-11 col-12">
              <p
                class="text-secandry text22"
                data-aos="fade-up"
                style={{ textAlign: "justify" }}
              >
                We strive to be leaders in the field of masterbatch by offering
                not only pigmented and special effect solutions but also by
                exploring new ideas, technologies, and additives to enhance our
                product portfolio.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="application">
        <div class="d-flex flex-nowrap">
          {data.map((el) => {
            return (
              <div style={{ width: "350px", height: "350px" }}>
                <div
                  class="applicationCart"
                  style={{
                    backgroundImage: `url(${el.img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div class="applicationCartTitle d-grid justify-content-center ">
                    <div>
                      {/* <p class="fs-1 fw-semibold text-wite m-0">{el.title}</p>
                      <p class="fs-5" style={{ textDecoration: "underline" }}>
                        Read More
                      </p> */}
                    </div>
                  </div>
                  <div
                    class="applicationCartText"
                    style={{ background: `${el.bgcolor}` }}
                  >
                    <p
                      class="fs-1 fw-semibold mt-3 m-0"
                      style={{ color: `${el.tcolor}` }}
                    >
                      {el.title}
                    </p>
                    <p class="text18" style={{ color: `${el.tcolor}` }}>
                      {el.text}
                    </p>
                    <button
                      class="btn btn-primary rounded-5 fw-bold p-3 mt-5"
                      style={{}}
                    >
                      Explore More
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div style={{ background: "#1F2224" }}>
        <p class="display-3 fw-bold text-white py-5">FAQs</p>
        {/* <div class='col-11 m-auto'> 
          {
            faq.map((el,i)=>{
              return(
                <div class='rounded-3 mt-4'>
                  <div class='d-flex justify-content-between text-start p-3 rounded-3' style={{background:'#303335',color:'white'}} data-bs-toggle="collapse" href={`#${i}`} role="button" aria-expanded="false" aria-controls="collapseFaq">
                    <div><p class='fs-5'>{el.que}</p></div>
                    <div>
                      <p class='fs-4'><i class="bi bi-arrow-down-circle-fill"></i></p>
                    </div>
                  </div> 
                  <div class="collapse" id={i}>
                    <div class="card card-body text-start" style={{borderRadius:'0 0 10px 10px'}}>
                      <p class='col-md-10 col-12'>Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.</p> 
                    </div>
                  </div>  
                </div>
              )
            })
          }

        </div> */}

        <div class="col-11 m-auto">
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq1
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq1(!faq1)}
              data-bs-toggle="collapse"
              href="#faq1"
              role="button"
              aria-expanded="false"
              aria-controls="faq1"
            >
              <div>
                <p class="fs-5 ">
                  How does Plastic Innovations Inc. ensure accurate color
                  matching?
                </p>
              </div>
              <div>
                {faq1 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq1">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  We utilize Pantone codes or sample parts to achieve precise
                  color matching, ensuring consistency and accuracy in our color
                  formulations.
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq2
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq2(!faq2)}
              data-bs-toggle="collapse"
              href="#faq2"
              role="button"
              aria-expanded="false"
              aria-controls="faq2"
            >
              <div>
                <p class="fs-5 ">
                  How long does the color matching process take?
                </p>
              </div>
              <div>
                {faq2 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq2">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our typical color matching process can be completed within 2-3
                  business days. If you require a quicker turnaround, we offer
                  an expedited 24-hour color match service at no additional
                  charge.
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq3
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq3(!faq3)}
              data-bs-toggle="collapse"
              href="#faq3"
              role="button"
              aria-expanded="false"
              aria-controls="faq3"
            >
              <div>
                <p class="fs-5 ">
                  What is the formulation process for creating unique formulas?
                </p>
              </div>
              <div>
                {faq3 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq3">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our state-of-the-art lab employs a scientific approach to
                  formulate unique formulas by carefully combining pigments and
                  additives, resulting in vibrant and customized colors.
                </p>
              </div>
            </div>
          </div>
          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq4
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq4(!faq4)}
              data-bs-toggle="collapse"
              href="#faq4"
              role="button"
              aria-expanded="false"
              aria-controls="faq4"
            >
              <div>
                <p class="fs-5 ">
                  Can I see color samples before making a decision?
                </p>
              </div>
              <div>
                {faq4 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq4">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Absolutely! We provide color chips for customer approval,
                  showcasing a wide range of colors available through Plastic
                  Innovations Inc. These color chips help you visualize the
                  final result and make informed decisions.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq5
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq5(!faq5)}
              data-bs-toggle="collapse"
              href="#faq5"
              role="button"
              aria-expanded="false"
              aria-controls="faq5"
            >
              <div>
                <p class="fs-5 ">
                  How does the decision-making process work between color
                  concentrate and pre-color options?
                </p>
              </div>
              <div>
                {faq5 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq5">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our experts will guide you through the decision-making process
                  based on your specific requirements. We take into account
                  factors such as production volume, desired color accuracy, and
                  application to determine the most suitable option for your
                  project.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq6
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq6(!faq6)}
              data-bs-toggle="collapse"
              href="#faq6"
              role="button"
              aria-expanded="false"
              aria-controls="faq6"
            >
              <div>
                <p class="fs-5 ">
                  What is the timeline for production and delivery?
                </p>
              </div>
              <div>
                {faq6 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq6">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Our streamlined production processes enable us to maintain
                  shorter lead times. Our normal lead time is less than 10
                  business days. The exact timeline will depend on the project
                  scope and specifications. Rest assured, we prioritize prompt
                  delivery to meet your deadlines.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq7
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq7(!faq7)}
              data-bs-toggle="collapse"
              href="#faq7"
              role="button"
              aria-expanded="false"
              aria-controls="faq7"
            >
              <div>
                <p class="fs-5 ">
                  Does Plastic Innovations Inc. offer customization and
                  personalized consultations?
                </p>
              </div>
              <div>
                {faq7 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq7">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Absolutely! We pride ourselves on providing customized
                  solutions tailored to your unique needs. Our team of experts
                  offers personalized consultations to understand your
                  requirements and ensure that we deliver the best possible
                  results.
                </p>
              </div>
            </div>
          </div>

          <div class=" mt-4">
            <div
              class="d-flex justify-content-between text-start p-3 border-none"
              style={
                faq8
                  ? {
                      background: "#ffffff",
                      color: "black",
                      borderRadius: "10px 10px 0px 0px",
                    }
                  : {
                      background: "#303335",
                      color: "white",
                      borderRadius: "10px 10px 10px 10px",
                    }
              }
              onClick={() => setFaq8(!faq8)}
              data-bs-toggle="collapse"
              href="#faq8"
              role="button"
              aria-expanded="false"
              aria-controls="faq8"
            >
              <div>
                <p class="fs-5 ">
                  What quality assurance measures does Plastic Innovations Inc.
                  have in place?
                </p>
              </div>
              <div>
                {faq8 ? (
                  <p class="fs-4 text-primary">
                    <BsFillArrowDownCircleFill />
                  </p>
                ) : (
                  <p class="fs-4">
                    <BsArrowRightCircleFill />
                  </p>
                )}
              </div>
            </div>
            <div class="collapse" id="faq8">
              <div
                class="card card-body text-start"
                style={{ borderRadius: "0 0 10px 10px", border: "none" }}
              >
                <p class="col-md-10 col-12">
                  Quality is our utmost priority. We have rigorous quality
                  control measures in place, and our products meet or exceed
                  industry standards. Additionally, we hold relevant
                  certifications that validate our commitment to maintaining the
                  highest quality standards.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary rounded-5 fw-bold p-4 my-5">
          More FAQs
        </button>
      </div>

      <div class="applicationFooter">
        <br />
        <FooterFirst />
        <br />
      </div>
    </div>
  );
};

export default Application;
