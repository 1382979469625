import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FooterFirst from "../FooterFirst";
import { useState } from "react";
import {
  BsArrowRightCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiFillLinkedin } from 'react-icons/ai';
import axios from "axios";
import Swal from "sweetalert2";

 

const Contact = () => {
  const [contact,setContact] = useState({})

  const contactInfo = (e)=>{ 
    const {name,value}= e.target;
    setContact({...contact,[name]:value})
}

// console.log(contact);
 const submitContact = () =>{
  if(
      contact?.Name && contact.Name != "" &&
      contact?.Email && contact.Email != "" &&
      contact?.Phone && contact.Phone != "" &&
      contact?.Comment && contact.Comment != ""  
    ){ 
      axios.post(`https://hi-labsolution.net/plastic/ContactNow.php`,contact)
      .then((res) => { 
        if (res.data.responseStatus == true) { 
          Swal.fire({
            position: "center",
            icon: "success",
            title:`${res.data.responseMessage}` ,
            showConfirmButton: false, 
            timer: 2000,
          });  
          setContact({
            Name: "",
            Email: "",
            Phone: "",
            Comment: "", 
          });
        } else { 
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${res.data.responseMessage}`,
            // showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err)=>{
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${err.message}`,
          showConfirmButton: false,
          timer: 2000,
        });
      })
    }else{
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All fields must be required!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  return (
    <div>
      {" "}
      <div style={{ height: "80px" }}></div>
      <div
        class="text-start col-11 m-auto"
        style={{ borderBottom: "1px solid" }}
      >
        <p class="display-1 fw-bold my-3" data-aos="fade-up">
          Contact us{" "}
        </p>
      </div>
      <div class="row col-11 m-auto mt-5 ">
        <div class="col-lg-4 col-12 ">
          <div class="col-md-11 col-12 text-start my-4">
            <p class="fw-bold fs-3" data-aos="fade-up">
              Let’s start a project together.
            </p>
            <p class=" fs-3" data-aos="fade-up">
              <span class="">Enquire today to learn how we can help your </span>{" "}
              <span class="fw-bold">business</span>
            </p>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <div class="rounded-5" style={{ background: "#73FAC9" }} data-aos="fade-up">
            <div class="col-11 m-auto ">
              <br />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Your Name"
                name="Name" 
                value={contact?.Name}
                onChange={contactInfo}
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Email"
                name="Email" 
                value={contact?.Email} 
                onChange={contactInfo} 
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Mobile Number"
                name="Phone" 
                value={contact?.Phone}  
                onChange={contactInfo} 
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Tell us more about your requirements"
                name="Comment" 
                value={contact?.Comment}   
                onChange={contactInfo} 
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
              />
              <div class="d-flex justify-content-end">
                <button class="btn btn-primary rounded-5 fw-semibold p-3 my-5" onClick={submitContact}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-start col-11 m-auto my-5">
        <p class="fw-bold col-md-8 col-12 fs-2" data-aos="fade-up">
          If you prefer, send us an email for new business opportunities or job
          openings.
        </p>
      </div>
      <div class="row col-11 m-auto mt-5 ">
        <div class="col-lg-5 col-12 "></div>
        <div class="col-lg-7 col-12">
          <div class="row">
            <div class="col-md-7 col-12 text-start" data-aos="fade-up">
              <p class="fs-4">Mail to:</p>
              <p class="fs-4 fw-bold">info@Plasticinnovations.com</p>
            </div>
            <div class="col-md-5 col-12 text-start" data-aos="fade-up">
              <p class="fs-4">Call Us:</p>
              <p class="fs-4 fw-bold">+1 951 361 0251</p>
            </div>
          </div>

          <div class=" text-start my-4" data-aos="fade-up">
            <p class="fs-4">Office Address:</p>
            <p class=" fs-4 fw-bold">
              10513 San Sevaine WayJurupa Valley, CA USA 91752
            </p>
          </div>

          <div class=" text-start" data-aos="fade-up">
            <p class="fs-4">Follow us on:</p>
            <a
              style={{ textDecoration: "underline", color: "black" }}
              class="  fw-bold"
              target="_blank"
              href="https://www.linkedin.com/company/plastic-innovations-inc/"
            >
             <span  class='fs-2'><AiFillLinkedin/></span>  
            </a>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <FooterFirst />
        <br />
      </div>
    </div>
  );
};

export default Contact;
