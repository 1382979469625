import React, { useEffect } from "react";
import "./about.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import AOS from "aos";
import "aos/dist/aos.css";
import { AboutOurValue, OurPartners } from "../home/HomeCurocel";
import FooterFirst from "../FooterFirst";
import ScrolledImg from "../ScrolledImg";
import { AiFillLinkedin } from 'react-icons/ai';


const ourValue = [
  {
    icon: `${require("./aboutImg/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
  },
  {
    icon: `${require("./aboutImg/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
  },
  {
    icon: `${require("./aboutImg/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
  },
  {
    icon: `${require("./aboutImg/icon.png")}`,
    title: "Lorem ipsum dolor enim ad minim",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
  },
];

const ourTeam = [
  {
    img: `${require("./aboutImg/a1.png")}`,
    name: "Person Name",
    profile: " Job profile",
  },
  {
    img: `${require("./aboutImg/a2.png")}`,
    name: "Person Name",
    profile: " Job profile",
  },
  {
    img: `${require("./aboutImg/a3.png")}`,
    name: "Person Name",
    profile: " Job profile",
  },
  {
    img: `${require("./aboutImg/a4.png")}`,
    name: "Person Name",
    profile: " Job profile",
  },
  {
    img: `${require("./aboutImg/a5.png")}`,
    name: "Person Name",
    profile: " Job profile",
  },
];

const partners = [
  {
    img: `${require("../../images/home/c1.png")}`,
  },
  {
    img: `${require("../../images/home/c2.png")}`,
  },
  {
    img: `${require("../../images/home/c3.png")}`,
  },
  {
    img: `${require("../../images/home/c4.png")}`,
  },
  {
    img: `${require("../../images/home/c5.png")}`,
  },
  {
    img: `${require("../../images/home/c1.png")}`,
  },
  {
    img: `${require("../../images/home/c2.png")}`,
  },
  {
    img: `${require("../../images/home/c3.png")}`,
  },
  {
    img: `${require("../../images/home/c4.png")}`,
  },
  {
    img: `${require("../../images/home/c5.png")}`,
  },
];

const partner = () => {
  let arr = [];
  for (let i = 0; i < partners.length; i++) {
    arr.push(
      <div>
        <img style={{ width: "110px" }} src={partners[i].img} alt="" />
      </div>
    );
  }
  return arr;
};
const OurValues = () => {
  const arr = [];
  for (let i = 0; i < ourValue.length; i++) {
    arr.push(
      <div class="col-12 text-start p-3" data-aos="fade-up">
        <img
          src={require("./aboutImg/icon.png")}
          style={{ width: "60px" }}
          alt=""
        />
        <p class="fw-bold fs-5 my-2">Lorem ipsum dolor enim ad minim</p>
        <p class="">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididun
        </p>
      </div>
    );
  }
  return arr;
};

const AboutUs = ({data}) => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  return (
    <div>
      <div>
        <div style={{ height: "80px" }}></div>
        <div
          class="text-start col-11 m-auto"
          style={{ borderBottom: "1px solid" }}
        >
          <p class="display-1 fw-bold my-3" data-aos="fade-up">
            ABOUT US
          </p>
        </div>
        <div class="row col-11 m-auto">
          <div class="col-lg-4 col-12 " style={{ borderRight: "1px solid" }}>
            <div class="col-md-11 col-12 text-start mt-4">
              <p class="fw-bold fs-3" data-aos="fade-up">
                We are designed to improve the appearance, performance, value &
                sustainability of plastic moldings.
              </p>
            </div>
          </div>
          <div class="col-lg-8 col-12">
            <div class="col-md-10 col-12 text-start mt-4 row">
              <div class="col-lg-1 col-12"></div>
              <div class="col-lg-11 col-12">
                <p
                  class="text-secandry text22"
                  data-aos="fade-up"
                  style={{ textAlign: "justify" }}
                >
                  PII provides innovative polymer materials, application
                  development, and custom compounds and composites for
                  engineering applications. PII is in the business of
                  innovation, commercialization, and manufacture of polymer
                  alloys, blends, additives and composites with extreme
                  functional performance properties.
                </p>
              </div>
            </div>
            <div class="col-md-d-none" style={{ height: "150px" }}></div>
          </div>
        </div>

        <div class="ourValues text-white">
          <div class="ourValuesHide" style={{ height: "300px" }}></div>
          <div class="col-12 m-auto text-start ">
            <div class="col-11 m-auto">
              <br />
              {/* <p class='display-3 mb-5' data-aos="fade-up"> <span>Our</span> <span class='fw-bold' >Values</span></p> */}
            </div>
            <div class="row col-12 ">
              <div class="col-lg-6 col-12">
                <div class="col-11 m-auto row">
                  <div
                    class="col-sm-6 col-12 text-start p-3"
                    data-aos="fade-up"
                  >
                    <p class="fw-bold fs-3 my-2">
                      Empowering Industries through Plastic Solutions
                    </p>
                    <p class="" style={{ textAlign: "justify" }}>
                      is to transform plastic modules into visually stunning
                      works of art, elevating the aesthetic appeal and market
                      competitiveness of our clients' products.
                    </p>
                  </div>
                  <div
                    class="col-sm-6 col-12 text-start p-3"
                    data-aos="fade-up"
                  >
                    <p class="fw-bold fs-3 my-2">
                      Transforming Plastic Possibilities
                    </p>
                    <p class="" style={{ textAlign: "justify" }}>
                      Our mission is to transform plastic possibilities into
                      reality. Through advanced research, state-of-the-art
                      facilities, and a team of industry experts, we develop and
                      deliver innovative products and services that exceed
                      expectations. We aim to create sustainable and
                      high-performance plastic solutions that contribute to a
                      better future.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div
                  class="col-lg-10 col-md-11 col-11 m-auto "
                  data-aos="zoom-in"
                >
                  <img
                    src={require("../../images/home/home4.jpg")}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50px",
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>

      {/* <div class='col-11 m-auto text-start'>
            <p class='display-3 my-5' data-aos="fade-up"><span>Our</span> <span class='fw-bold'>Team</span> </p> 

            <div class='ourTeam'>
                <div class='my-3' data-aos="fade-up">
                    {
                    ourTeam?.map((el,i)=>{
                        return(
                            <div style={{width:'250px',borderRadius:'30px'}} >
                                <img src={el.img} style={{width:'250px',height:'100%'}} alt="" /> 
                            </div>
                        )
                    }) 
                    }
                </div>
            </div>
            <div class='col-12 my-5' style={{position:'relative'}}> 
                <div class='ourPartnerCercul'>
                   <ScrolledImg/>
                </div>
                <div class='col-sm-10 col-12'> 
                    <p class='fs-5' data-aos="fade-up">quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <button class='btn btn-primary rounded-5 fw-bold py-3 px-4 clearBtn my-4 mb-5' data-aos="fade-up">Become Team Member</button>
                    
                </div>
            </div>
        </div> */}

      <div class="col-11 m-auto text-start">
        <p class="display-3 my-5" data-aos="fade-up">
          <span>Our</span> <span class="fw-bold">Story</span>{" "}
        </p>
        <div class="col-12 my-5" style={{ position: "relative" }}>
          <div class="ourPartnerCercul">
            <ScrolledImg data={data}/>
          </div>
          <div class="col-sm-10 col-12">
            <p class="fs-5" data-aos="fade-up" style={{ textAlign: "justify" }}>
              Plastic Innovation was born out of a passion for transforming the
              possibilities of plastics. Our journey began with a small team of
              visionaries who recognized the potential of this versatile
              material. Over the years, we have grown and evolved, continually
              pushing boundaries and setting new industry standards.{" "}
            </p>
            <br />
            <p class="fs-5" data-aos="fade-up" style={{ textAlign: "justify" }}>
              Today, Plastic Innovation stands as a trusted partner to numerous
              industries, offering a comprehensive range of services including
              color concentrates, pre-color resin, additives and minerals
              compounding, and more. Our solutions empower businesses to achieve
              their goals, meet market demands, and leave a lasting impact.{" "}
            </p>
            <br />
            <p class="fs-5" data-aos="fade-up" style={{ textAlign: "justify" }}>
              Join us on this remarkable journey as we continue to unleash the
              power of plastics and shape a brighter future for industries
              worldwide.{" "}
            </p>
            <br />
            <p
              class="fs-5 fw-bold"
              data-aos="fade-up"
              style={{ textAlign: "justify" }}
            >
              Contact us today to discover how Plastic Innovation can power your
              success with our transformative plastic solutions.{" "}
            </p>
            <br />
            <br />
            <br />
            {/* <button class='btn btn-primary rounded-5 fw-bold py-3 px-4 clearBtn my-4 mb-5' data-aos="fade-up">Become Team Member</button> */}
          </div>
        </div>
      </div>

      <div class="ourPartner text-white">
        <div class="ourPartnerHide" style={{ height: "100px" }}></div>
        <div class="col-12 m-auto text-end ">
          <div class="col-11 m-auto">
            <br />
            <p class="display-3 mb-5" data-aos="fade-up">
              {" "}
              <span>Our</span> <span class="fw-bold">Founder</span>
            </p>
          </div>
          
          <div class="col-12 row flex-column-reverse flex-lg-row m-auto ">
            <div class="col-lg-6 col-12 d-flex justify-content-end align-items-center">
              
              <div class="col-11  text-end">
              <p class="fw-bold fs-1" data-aos="fade-up">
                Dennis Savalia  
                </p>
                <p class="fs-5" data-aos="fade-up">
                Founder     
                </p>
                <br />
                <div class='d-flex justify-content-end'>

                <hr  class='col-lg-10 text-end'/>
                </div>
                <br />
                <p class="fw-bold fs-1" data-aos="fade-up">
               Mike Savalia
                </p>
                <p class="fs-5" data-aos="fade-up">
              Managing Director ,
                  <span>     <a
              style={{ textDecoration: "underline", color: "white" }}
              class="  fw-bold"
              target="_blank"
              href="https://www.linkedin.com/in/mike-savaliya-347b444b/"
            >
             <span  class='fs-3'  ><AiFillLinkedin/></span>  
            </a></span>
                </p>
           
                <br />
                <div class='d-flex justify-content-end'>

                <p  class='col-lg-10 '  data-aos="fade-up" style={{textAlign:'justify'}}>Mike is a Managing Director in the realm of plastic colors, combines a wealth of experience with visionary leadership. His track record showcases a commitment to innovation and excellence in navigating the nuances of the plastic coloration industry.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 d-flex  align-items-center">
              <div
                class="col-lg-8 col-md-10 col-11 m-auto "
                data-aos="zoom-in"
                style={{
                  background: `url(${require("../aboutUs/aboutImg/ourFounderBg2.png")})`,
                  backgroundSize: "100% 100%",
                }}
              >
                <br />
                <br />
                <div class="col-8 m-auto">
                  <div style={{ height: "100%", height: "300px" }}></div>
                  {/* <img  src={require('./aboutImg/a5.png')} style={{width:'100%',height:'100%'}} alt="" /> */}
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div> 
      <div class="aboutFooter">
        <FooterFirst />
      </div>
    </div>
  );
};

export default AboutUs;
