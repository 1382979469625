import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Style.css";

const Navbar = ({ data }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const nav = document.querySelector(".navbarMain");
    let lastScrollY = window.scrollY;
    nav.classList.add("navbarMain--hidden");
    window.addEventListener("scroll", () => {
      if (lastScrollY < window.scrollY) {
        nav.classList.add("navbarMain--hidden");
      } else {
        nav.classList.remove("navbarMain--hidden");
      }
      lastScrollY = window.scrollY;
    });
  });

  useEffect(() => {
    // setTimeout(()=>{
    setShow(true);
    // },3700)
  }, []);

  const navigate = useNavigate();

  const Nav = () => {
    return (
      <div class="navbarMain mt-1 " style={{ width: "100%" }}>
        <div
          class=""
          style={{
            width: "93%",
            margin: "auto",
            borderRadius: "50px",
            background: "#f5f5f5",
          }}
        >
          <div class="d-flex align-items-center justify-content-between mainNav">
            <div class="d-flex align-items-center">
              <img
                class="p-1"
                onClick={() => navigate("/")}
                style={{
                  height: "50px",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                src={require("../images/home/logo.png")}
                alt=""
              />
            </div>
            <div class="d-flex align-items-center">
              <div class="decktopNav">
                <ul
                  class="d-flex gap-4 align-items-center  m-0 "
                  style={{ listStyle: "none", fontWeight: "500" }}
                >
                  <li class="" onClick={() => navigate("/about-us")}>
                    About Us
                  </li>
                  {/* <li class='' onClick={()=>navigate('/pre-colored-resin')} >Our Services</li> */}

                  <li class="dropdown">
                    <p
                      style={{ cursor: "pointer" }}
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Our Services
                    </p>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          // href="/pre-colored-resin"
                          onClick={() => navigate("/pre-colored-resin")}
                        >
                          Pre colored resin
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          // href="/color-consentrate"
                          onClick={() => navigate("/color-consentrate")}
                        >
                          Color Concentrate
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          // href="/additives-minerals"
                          onClick={() => navigate("/additives-minerals")}
                        >
                          Additives & Minerals compounding
                        </a>
                      </li>
                    </ul>
                  </li>
                  {/* <li class='' onClick={()=>navigate('/application')}>Applications</li> */}
                  <li class="" onClick={() => navigate("/product")}>
                    Product Development
                  </li>
                  <li class="" onClick={() => navigate("/contact")}>
                    Contact us
                  </li>
                  <li>
                    <button
                      class="btn btn-primary  rounded-5 p-3"
                      onClick={() => data(true)}
                      style={{ marginLeft: "50px" }}
                    >
                      Request Free Color Match
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <button
                  class="btn mobileNav"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasHeader"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <i class="bi bi-list"></i>
                </button>
              </div>

              {/* mobile navbar start  */}
              <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasHeader"
                aria-labelledby="offcanvasHeaderLabel"
              >
                <div class="offcanvas-header">
                  <div>
                    <img
                      style={{ height: "50px", cursor: "pointer" }}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      src={require("../images/home/logo.png")}
                      alt=""
                      onClick={() => navigate("/")}
                    />
                  </div>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body ">
                  <div>
                    <ul
                      class="d-grid gap-2 text-center  m-0 mt-3 p-0"
                      style={{
                        listStyle: "none",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <li
                        onClick={() => navigate("/about-us")}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <p style={{ cursor: "pointer" }} class="fs-5 ">
                          About Us
                        </p>
                      </li>

                      {/* <a class="fs-5" href="/pre-colored-resin" >
                        Pre Colored Resin
                      </a>

                      <a class="fs-5" href="/color-consentrate">
                        Color Concentrate
                      </a>

                      <a class="fs-5" href="/additives-minerals">
                        Additives & Minerals compounding
                      </a> */}

                   
                      <li
                        data-bs-toggle="collapse"
                        href="#ourServices"
                        role="button"
                        aria-expanded="false"
                        aria-controls="ourServices"
                      >
                        <p style={{ cursor: "pointer" }} class="fs-5 ">
                          Our Services{" "}
                          <span>
                            {" "}
                            <i class="bi bi-chevron-down"></i>
                          </span>{" "}
                        </p>
                        <div class="collapse" id="ourServices">
                          <div class="card card-body border-0 p-2">
                            <p
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => navigate("/pre-colored-resin")}
                            >
                              Pre Colored Resin
                            </p>
                            <p
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => navigate("/color-consentrate")}
                            >
                              Color Concentrate
                            </p>
                            <p
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => navigate("/additives-minerals")}
                            >
                              Additives & Minerals compounding
                            </p>
                          </div>
                        </div>
                      </li>
                      {/* <li onClick={()=>navigate('/application')}  data-bs-dismiss="offcanvas" aria-label="Close">
                                             <p style={{cursor:'pointer'}} class='fs-5 '>Applications</p> 
                                        </li> */}
                      <li
                        onClick={() => navigate("/product")}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <p style={{ cursor: "pointer" }} class="fs-5 ">
                          Product Development
                        </p>
                      </li>
                      <li
                        onClick={() => navigate("/contact")}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <p style={{ cursor: "pointer" }} class="fs-5 ">
                          Contact Us
                        </p>
                      </li>
                      <li
                        onClick={() => data(true)}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <button class="btn btn-primary mx-5 rounded-5">
                          Request Free Color Match
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* mobile navbar end  */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div style={show == true ? { display: "block" } : { display: "none" }}>
      {Nav()}
    </div>
  );
};

export default Navbar;
