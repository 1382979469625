 
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
 
import { Footer } from './components/Footer'; 
import Home from './components/home/HomeCopy';
import AboutUs from './components/aboutUs/AboutUs';
 
import Application from './components/application/Application';
import Contact from './components/contact/Contact';
import Products from './components/Products/Products';
 
import ScrollToTop from './components/ScrollToTop';
 
import Request from './components/request/Request';
import { useState } from 'react';
import { useEffect } from 'react';
 
import PreColor from './components/PreColorResin';
import Additives from './components/AdditivesAndMinerals';
import ColorConc from './components/ColorConcentrate';
import PrivacyPolicy from './components/request/PrivacyPolicy';
 
 
 
 
 
 
 
 

function App() {
 
  const [show,setShow] = useState()
  const [showPolicy,setShowPolicy] = useState()

 
  const [request,setRequest] = useState()
  const [policy,setPolicy] = useState()

  useEffect(()=>{
    setRequest(show)
  },[show]) 

  useEffect(()=>{
    setPolicy(showPolicy)
  },[showPolicy]) 
 
  return (
    <div className="App">
    <ScrollToTop/>
      <Navbar data={setShow}  />
      {request ? <Request data={setShow}/> :''} 
      {policy ? <PrivacyPolicy data={setShowPolicy}/> :''} 

       
      <Routes>
        <Route path='/' element={<Home data={setShow}/>}/>
        <Route path='/about-us' element={<AboutUs data={setShow}/>}/> 
        <Route exact path='/pre-colored-resin' element={<PreColor/>}/> 
        <Route exact path='/additives-minerals' element={<Additives/>}/> 
        <Route exact path='/color-consentrate' element={<ColorConc/>}/>  
        {/* <Route path='/application' element={<Application/>}/>  */}
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/product' element={<Products data={setShow}/>}/>
         
      </Routes>
      <Footer data={setShowPolicy}/>
 
    </div>
  );
}

export default App;
