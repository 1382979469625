import React, { useState, useEffect } from "react";
import "./leftSlide.css";

const LeftSlide = () => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [ticking, setTicking] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 1.0,
    });

    const container = document.querySelector(".researchSticky-scroller");
    const target = document.querySelector("[data-scroll-research]");
    observer.observe(target);

    function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener("scroll", onScroll);
        } else {
          // window.removeEventListener('scroll', onScroll);
        }
      });
    }

    const update = () => {
      const bounds = container.getBoundingClientRect();
      const percentage = ((Math.abs(bounds.y) * 100) / bounds.height) * 2;
      const clipPath = `inset(0 ${percentage}% 0  0 )`;
      target.style.clipPath = clipPath;
      setTicking(false);
    };

    const requestTick = () => {
      if (!ticking) {
        window.requestAnimationFrame(update);
        setTicking(true);
      }
    };

    const onScroll = () => {
      setLastScrollY(window.scrollY);
      requestTick();
    };

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <main class="researchSlidMain">
      <div className="researchSticky-scroller" data-scroller>
        <div className="researchSticky">
          <div
            className="research researchLight researchAbsolute"
            data-scroll-research
          >
            <div class="text-start col-12">
              <div class=" col-12 text-start my-5">
                <p class="display-1 fw-semi">Advance</p>
                <p class="display-1 fw-bold">Research Lab</p>
              </div>
              <br />
              <div style={{ height: "50vh", borderRadius: "50px" }}>
                {/* <img src={require('../images/home/home14.png')} style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50px'}}   alt=""  />   */}
              </div>
            </div>
          </div>
          <div className="research researchDark">
            <div class="text-start col-12">
              <div class=" col-12 text-start my-5">
                <p class="display-1 fw-semi">Advance</p>
                <p class="display-1 fw-bold">Research Lab</p>
              </div>
              <br />
              <div style={{ height: "50vh", borderRadius: "50px" }}>
                <img
                  src={require("../../images/home/leb-Images.jpg")}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50px",
                  }}
                  alt=""
                /> 
              </div>
            </div>
          </div>
        </div>
        <div className="research"></div>
      </div>
    </main>
  );
};

export default LeftSlide;
