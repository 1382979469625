import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import "../Style.css";

const Request = ({ data }) => {
  const [contact, setContact] = useState({});

  const contactInfo = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  }; 
  const submitData = () => {
    if (
      contact.Name &&
      contact.Name != "" &&
      contact.Email &&
      contact.Email != "" &&
      contact.Phone &&
      contact.Phone != "" &&
      contact.CompanyName &&
      contact.CompanyName != "" &&
      contact.CompanyAddress &&
      contact.CompanyAddress != "" &&
      contact.Zip &&
      contact.Zip != "" &&
      contact.State &&
      contact.State != "" &&
      contact.Country &&
      contact.Country != "" &&
      contact.ColorDesc &&
      contact.ColorDesc != "" &&
      contact.PantoneCode &&
      contact.PantoneCode != "" &&
      contact.Production &&
      contact.Production != "" &&
      contact.MoldingResin &&
      contact.MoldingResin != "" &&
      contact.Restrictions &&
      contact.Restrictions != "" &&
      contact.AdditionalInformation &&
      contact.AdditionalInformation != "" 
   
    ) {
      axios
        .post(`https://hi-labsolution.net/plastic/EnquiryNow.php`, contact)
        .then((res) => {
          if (res.data.responseStatus == true) {
            // alert( `${res.data.responseMessage}`)
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                "Your details have been successfully submitted. Our team will contact you soon.",
              
              // timer: 2000,
            });

            data(false);
            setContact({
              Name:'',
              Email:'',
              Phone:'',
              CompanyName: "",
              CompanyAddress: "",
              Zip:'',
              State: "",
              Country: "",
              ColorDesc: "",
              PantoneCode: "",
              Production: "",
              MoldingResin:"",
              Restrictions: "",
              AdditionalInformation:''
              
            });
          } else {
            // alert( `${res.data.responseMessage}`)
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${res.data.responseMessage}`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //  alert( `Fill all Fildes`)
      Swal.fire({
        position: "center",
        icon: "error",
        title: `All fields must be required!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        top: "0",
        background: "rgba(0,0,0,.8)",
        zIndex: "2",
      }}
    >
      <br />
      <br />
      <div
        class="col-lg-8 col-11 m-auto"
        style={{
          height: "85vh",
          overflowY: "auto",
        }}
      >
        <div class="text-end">
          <p style={{ cursor: "pointer" }}>
            <i class="bi bi-x-lg text-white" onClick={() => data(false)}></i>
          </p>
          <br />
        </div>
        <div class="" style={{ background: "#73FAC9" }}>
          <div class="col-11 m-auto ">
            <br />
            <p class="fw-bold fs-3 text-start">Enquire Now</p>
            <input
              type="text"
              class="form-control mt-3"
              placeholder="Your Name"
              name="Name"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />
              <input
              type="text"
              class="form-control mt-3"
              placeholder="Email"
              name="Email"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />
              <input
              type="text"
              class="form-control mt-3"
              placeholder="Phone Number"
              name="Phone"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />
            <input
              type="text"
              class="form-control mt-3"
              placeholder="Company Name:"
              name="CompanyName"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />
            <input
              type="text"
              class="form-control mt-3"
              placeholder="Company address"
              name="CompanyAddress"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />
            <div class="d-flex gap-4">
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Zip"
                name="Zip"
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
                onChange={contactInfo}
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="State"
                name="State"
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
                onChange={contactInfo}
              />
            </div>
            <input
              type="text"
              class="form-control mt-3"
              placeholder="Country"
              name="Country"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />

            <div class="d-flex gap-4">
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Color description"
                name="ColorDesc"
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
                onChange={contactInfo}
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Pantone (PMS) code"
                name="PantoneCode"
                style={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                }}
                onChange={contactInfo}
              />
            </div>
            <p class="fw-bold text-start mt-3">Production :</p>
            <div class="d-flex gap-4  flex-wrap mt-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Production"
                  id="flexRadioDefault1"
                  value="Compound"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Compound
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Production"
                  id="flexRadioDefault1"
                  value="Precolor"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Precolor
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Production"
                  id="flexRadioDefault1"
                  value="Concentrate"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Concentrate
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Production"
                  id="flexRadioDefault1"
                  value="Dry Color"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Dry Color
                </label>
              </div>
            </div>

            <input
              type="text"
              class="form-control mt-3"
              placeholder="Molding Resin"
              name="MoldingResin"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />

            <p class="fw-bold text-start mt-3">Restrictions :</p>
            <div class="d-flex gap-4  flex-wrap mt-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Restrictions"
                  id="flexRadioDefault1"
                  value="FDA"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  FDA
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Restrictions"
                  value="Medical"
                  id="flexRadioDefault1"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Medical
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Restrictions"
                  id="flexRadioDefault1"
                  value="Heavy Metals"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Heavy Metals
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Restrictions"
                  id="flexRadioDefault1"
                  value="CPSC"
                  onChange={contactInfo}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  CPSC
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Restrictions"
                  id="flexRadioDefault1"
                  onChange={contactInfo}
                  value="RoHs"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  RoHs
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Restrictions"
                  id="flexRadioDefault1"
                  onChange={contactInfo}
                  value="Other"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Other
                </label>
              </div>
            </div>

            <input
              type="text"
              class="form-control mt-3"
              placeholder="Additional Information"
              name="AdditionalInformation"
              style={{
                background: "none",
                border: "none",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
              onChange={contactInfo}
            />

            <div class="d-flex justify-content-end">
              <button
                class="btn btn-primary rounded-5 fw-semibold p-3 px-5 my-5"
                onClick={submitData}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;



 